// Galerie Jomie Pâtisserie
jQuery(document).ready(function () {
    jQuery("#jomie_gallery").nanogallery2({
        locationHash: true,
        itemsBaseURL: 'https://cdn.shopify.com/s/files/1/0551/3048/8888/files/',
        items:[
            { src: "ecd82fd1-e02a-4ae4-b5ef-7a22388ff491_w.jpg", title: "Abeilles et Marguerites", description: "\ud83c\udf82 Un g\u00e2teau pour c\u00e9l\u00e9brer le premier anniversaire de votre bout de chou\ud83d\udc76. \n\n\ud83c\udf3cD\u00e9cor\u00e9 de mignonnes marguerites aux p\u00e9tales bedonn\u00e9s et de petites abeilles en fondant, il apporte une touche de douceur et de charme. \n\n\ud83c\udf38 Con\u00e7u pour s'harmoniser avec les couleurs des d\u00e9corations de la f\u00eate, il s'int\u00e8gre parfaitement dans l'ambiance. \n" },
            { src: "db65c95d-39f8-478d-b8ab-ca4a1bd2ba59_w.jpg", title: "Amour de Stitch", description: "\ud83c\udf82 D\u00e9couvrez notre g\u00e2teau con\u00e7u sp\u00e9cialement pour les fans de ce charmant personnage de dessin anim\u00e9. Chaque g\u00e2teau est personnalis\u00e9 avec le pr\u00e9nom de l'enfant c\u00e9l\u00e9br\u00e9, mis en valeur sur un bandeau en fondant \u00e0 la base, entour\u00e9 de c\u0153urs color\u00e9s.\n\n\ud83d\udc96 Parfait pour une f\u00eate d'anniversaire, ce g\u00e2teau est orn\u00e9 de fleurs d\u00e9licates en fondant, r\u00e9alis\u00e9es \u00e0 la main. Il comporte \u00e9galement un grand chiffre sur le dessus pour marquer l'\u00e2ge de l'enfant, ajoutant un sens tout particulier \u00e0 l'occasion.\n\n\ud83d\udc6a Id\u00e9al pour les parents qui souhaitent rendre l'anniversaire de leur enfant m\u00e9morable, ce g\u00e2teau marie simplicit\u00e9 et tendresse avec son design attrayant et son personnage ador\u00e9 par les petits. Offrez un moment magique \u00e0 votre enfant avec un g\u00e2teau qui refl\u00e8te l'amour pour son personnage pr\u00e9f\u00e9r\u00e9." },
            { src: "8b95cb94-f683-41d0-acae-519e8ce1b340_w.jpg", title: "Babe Noir et Or", description: "\ud83c\udf82\u2728 D\u00e9couvrez notre g\u00e2teau \u00e0 la cr\u00e8me au beurre bicolore pour c\u00e9l\u00e9brer avec \u00e9l\u00e9gance! Avec une base cr\u00e9meuse en d\u00e9grad\u00e9 de blanc et noir \u00e0 la truelle et un coulis de chocolat blanc dor\u00e9, ce dessert aux accents luxueux est un v\u00e9ritable festin pour les yeux autant que pour les papilles. \ud83d\udda4\ud83d\udc9b\n\n\ud83c\udf1f Chaque d\u00e9tail est pens\u00e9 pour surprendre: des macarons 'Jomie' croustillants garnis de cr\u00e8me au beurre et de feuilles d'or comestibles, aux boules et perles de sucre dor\u00e9es qui captent la lumi\u00e8re.\n\n\ud83c\udf81 Id\u00e9al pour un anniversaire ou tout moment de c\u00e9l\u00e9bration, ce g\u00e2teau est finalis\u00e9 par un \u00e9l\u00e9gant topper \"Happy Birthday\". Faites plaisir \u00e0 un \u00eatre cher ou offrez un moment de pur bonheur lors de votre prochain \u00e9v\u00e9nement. \ud83c\udf89\ud83c\udf88" },
            { src: "8a89464e-1448-4d42-8a39-317558e505ae_w.jpg", title: "Baby Girl", description: "\ud83d\udc76 Voici le doux pr\u00e9lude \u00e0 une merveilleuse aventure parentale. Notre g\u00e2teau pour baby shower est une ode \u00e0 la tendresse, avec ses couleurs qui \u00e9voquent les premiers moments de douceur et d'\u00e9merveillement \u00e0 partager avec votre petite fille.\n\n\ud83c\udf08 Comme un doux r\u00eave, un arc-en-ciel pastel s'\u00e9tend d\u00e9licatement sur le c\u00f4t\u00e9, symbolisant la joie lumineuse et les promesses d'avenir que porte chaque nouveau-n\u00e9.\n\n\ud83c\udf1f Au c\u0153ur du g\u00e2teau, \"Baby Girl\" est inscrit en lettres d'or, pr\u00eat \u00e0 annoncer avec d\u00e9licatesse le pr\u00e9nom choisi avec tant de soin.\n\n\ud83c\udf6b La base, ceinte de perles chocolat\u00e9es blanches, offre non seulement une finition charmante mais aussi une touche de d\u00e9lice \u00e0 savourer, comme les petits bonheurs quotidiens de la vie de famille.\n\n\ud83d\udecd\ufe0f Plus qu'un simple g\u00e2teau, c'est un symbole d'amour et de bienvenue pour votre enfant. Partagez ce moment, partagez ce g\u00e2teau, et commencez ce nouveau chapitre avec douceur et affection." },
            { src: "5b1b0a21-002b-4bac-baff-762f9e1fb893_w.jpg", title: "Baby Princesse Couronne", description: "\ud83c\udf80 Ce g\u00e2teau \u00e0 deux \u00e9tages est un doux hommage \u00e0 votre petite princesse. Avec sa grande couronne en fondant, il respire la magie et l\u2019amour.  \n\n\ud83c\udf38 Ses teintes d\u00e9licates, ses fleurs en fondant et ses perles dor\u00e9es font de ce g\u00e2teau un moment de pure tendresse \u00e0 partager en famille.  \n\n\ud83d\udc96 Parfait pour c\u00e9l\u00e9brer votre tr\u00e9sor avec chaleur et \u00e9merveillement." },
            { src: "9ae90de7-19aa-4f51-8925-c8479190fa0f_w.jpg", title: "Baby Shower Chaussons", description: "\ud83c\udf89 Ce g\u00e2teau pour baby shower est une douce mani\u00e8re de c\u00e9l\u00e9brer l'arriv\u00e9e d'un b\u00e9b\u00e9\ud83d\udc76. Avec ses nuances tendres de rose et de bleu, il est parfait pour une f\u00eate r\u00e9v\u00e9lant le sexe du b\u00e9b\u00e9. Les petites chaussures sur le dessus apportent une touche adorable qui fera fondre les c\u0153urs.\n\n\ud83d\udc96 Offrez-le aux futurs parents pour leur montrer votre affection et votre soutien. Les petits jouets et v\u00eatements de b\u00e9b\u00e9 \u00e9voquent des moments pr\u00e9cieux et ajoutent une chaleur particuli\u00e8re \u00e0 la c\u00e9l\u00e9bration. " },
            { src: "50dca9fb-c91a-42e4-83ac-00fe1c6da27a_w.jpg", title: "Baby Shower Corde \u00e0 Linge", description: "\ud83c\udf88 D\u00e9couvrez notre g\u00e2teau de c\u00e9l\u00e9bration \"BABY\", parfait pour les moments pr\u00e9cieux comme une baby shower ou l'annonce d'une naissance. Le dessus du g\u00e2teau arbore le mot \"BABY\" en grosses lettres de fondant orn\u00e9es de petits n\u0153uds papillon. Les ballons d\u00e9licatement pos\u00e9s au-dessus du \"B\" apportent une l\u00e9g\u00e8re touche de fantaisie.\n\n\ud83d\udc76 Sur le pourtour, une corde \u00e0 linge imaginative pr\u00e9sente des v\u00eatements miniatures pour b\u00e9b\u00e9, allant des bodys color\u00e9s aux petites robes, accroch\u00e9s avec soin \u00e0 c\u00f4t\u00e9 d'un ourson en peluche, symbolisant le compagnon toutou ins\u00e9parable de B\u00eab\u00ea.\n\n\ud83c\udf70 La base du g\u00e2teau est rehauss\u00e9e d'une bande \u00e9l\u00e9gante en fondant, apportant une finition soign\u00e9e. Ce g\u00e2teau est non seulement un r\u00e9gal pour les yeux mais promet aussi d'enchanter les papilles avec son go\u00fbt raffin\u00e9.\n" },
            { src: "e1691aa2-1022-4ed2-b009-adefc0dc1f8a_w.jpg", title: "Baby Shower Duo 2", description: "\ud83d\udc76\ud83c\udf89 En attente d'un nouveau membre dans la famille? C\u00e9l\u00e9brez ce moment exceptionnel avec notre g\u00e2teau sur deux \u00e9tages pour baby shower! Sa douce palette de cr\u00e8me au beurre en blanc, bleu et rose \u00e9tablit une ambiance accueillante et aimante.\n\n\ud83d\udc3b\ud83d\udc99\ud83d\udc97 Ajoutez une touche de douceur \u00e0 votre f\u00eate avec les deux mignons oursons assis paisiblement au sommet du g\u00e2teau. Tenant un c\u0153ur rose et un c\u0153ur bleu, ils incarnent simplement l'amour et l'excitation que tout le monde ressent \u00e0 l'approche de ce grand \u00e9v\u00e9nement.\n\n\u2601\ufe0f\u2601\ufe0f Pour une touche finale, des boules de fondant color\u00e9es et des petits nuages ornent le g\u00e2teau, ajoutant une petite dose de fantaisie et d'imaginaire \u00e0 ce g\u00e2teau. \n\n\ud83e\udd70\ud83c\udf70 \u00c0 la fois savoureux et visuellement captivant, ce g\u00e2teau sera le clou de votre baby shower, et un formidable sujet de conversation parmi vos invit\u00e9s." },
            { src: "cff5e6d9-cfd1-4cfa-be7d-6b2ac99f5388_w.jpg", title: "Baby Shower \u00c9l\u00e9phants", description: "\ud83c\udf70 \u00c0 la recherche du g\u00e2teau parfait pour votre baby shower ou reveal gender? D\u00e9couvrez notre cr\u00e9ation aux tons pastels, symbolisant \u00e0 merveille la douce anticipation de l'arriv\u00e9e d'un petit tr\u00e9sor.\n\n\ud83c\udfa8 Ses rayures blanches, bleues et roses apportent une touche d'\u00e9l\u00e9gance tout en symbolisant le myst\u00e8re de l'annonce. Le doux message \"Fille ou Gar\u00e7on\" au centre, entour\u00e9 de c\u0153urs, capture l'\u00e9motion de l'instant.\n\n\ud83d\udc18 Au-dessus, deux adorables b\u00e9b\u00e9s \u00e9l\u00e9phants en fondant tiennent chacun un ballon, rose et bleu, repr\u00e9sentant la joie de l'attente.\n\n\ud83d\udc9d Une d\u00e9licieuse mani\u00e8re de c\u00e9l\u00e9brer une future arriv\u00e9e. Partagez avec vos proches l'excitation de cet \u00e9v\u00e9nement !" },
            { src: "b3788916-2216-4db0-8877-c4fef1f3b832_w.jpg", title: "Baby Shower Oursonne", description: "\ud83e\uddf8 Ce g\u00e2teau doux et charmant est id\u00e9al pour c\u00e9l\u00e9brer les moments uniques tels que les baby showers ou les premiers anniversaires. Il est couronn\u00e9 d'une oursonne en fondant, fa\u00e7onn\u00e9e \u00e0 la main, qui apporte une touche de tendresse \u00e0 votre festivit\u00e9.\n\n\ud83c\udf38 La base du g\u00e2teau est orn\u00e9e d'un d\u00e9licat parterre de fleurs en fondant, ajoutant une touche de fra\u00eecheur et d'\u00e9l\u00e9gance. Le d\u00e9tail du noeud papillon et des rubans encadre parfaitement le pr\u00e9nom du b\u00e9b\u00e9, mis en valeur par des lettres dor\u00e9es sur un fond textur\u00e9.\n\n\ud83c\udf80 Con\u00e7u avec soin, ce g\u00e2teau combine tradition et cr\u00e9ativit\u00e9, faisant de lui le choix parfait pour les parents souhaitant offrir une c\u00e9l\u00e9bration m\u00e9morable \u00e0 leur enfant. Sa pr\u00e9sentation soign\u00e9e et ses d\u00e9tails personnalis\u00e9s en font une pi\u00e8ce centrale admirable pour toute occasion sp\u00e9ciale.\n\n\u2728 Chaque \u00e9l\u00e9ment de ce g\u00e2teau a \u00e9t\u00e9 pens\u00e9 pour enchanter et \u00e9merveiller, garantissant non seulement un spectacle visuel mais aussi une exp\u00e9rience gustative exquise. Ideal pour ceux qui cherchent \u00e0 marquer ce jalon avec une touche de douceur et d'affection." },
            { src: "e3a60e08-981a-4f0f-bac2-6dc8b88ecbee_w.jpg", title: "Ballerine Arabesque", description: "\ud83c\udf82 Imaginez la joie d'une jeune danseuse d\u00e9couvrant ce g\u00e2teau c\u00e9l\u00e9brant son amour pour la danse classique. La silhouette \u00e9l\u00e9gante d'une ballerine en arabesque ressort sur un fond de su\u00e7ons brillants, cr\u00e9ant une sc\u00e8ne f\u00e9erique.\n\n\ud83e\udd8b Orn\u00e9e de papillons fins et de douces boules en fondant, cette cr\u00e9ation capte l'essence de la d\u00e9licatesse et de l'\u00e9l\u00e9gance. Des petites meringues apportent une douceur additionnelle, tandis qu'un macaron 'Jomie' \u00e0 la cr\u00e8me au beurre offre une texture croquante exquise.\n\n\ud83c\udf70 Les d\u00e9tails dor\u00e9s affichant le pr\u00e9nom et l'\u00e2ge de la c\u00e9l\u00e9br\u00e9e mettent en lumi\u00e8re ce jour unique, faisant de ce g\u00e2teau le point central d'une f\u00eate inoubliable, tant pour les parents organisant l'\u00e9v\u00e9nement que pour les enfants.\n\n Ce g\u00e2teau est l'id\u00e9al pour un parent d\u00e9sireux de faire une surprise m\u00e9morable \u00e0 sa petite \u00e9toile, alliant esth\u00e9tique et saveurs d\u00e9licieuses." },
            { src: "bacc61b7-344e-4af3-8802-933230abe7df_w.jpg", title: "Ballerine Arabesque 2", description: "\ud83e\ude70 Laissez-vous emporter par la po\u00e9sie et la gr\u00e2ce de notre g\u00e2teau ballerine revisit\u00e9 avec des fleurs ! Chaque d\u00e9tail, du doux rose pastel \u00e0 la silhouette d\u00e9licate de la danseuse en pleine arabesque, raconte une histoire enchant\u00e9e. Les su\u00e7ons cristallins captent la lumi\u00e8re tel un ballet de reflets scintillants, cr\u00e9ant une atmosph\u00e8re magique autour de cette cr\u00e9ation. Un r\u00eave gourmand pour toutes les petites \u00e9toiles qui r\u00eavent de pirouetter et de briller sous les projecteurs, parfait pour un anniversaire m\u00e9morable.\n\n\ud83c\udf38 Des fleurs en sucre, finement sculpt\u00e9es, apportent une touche florale pleine de douceur et d'\u00e9l\u00e9gance \u00e0 ce mod\u00e8le unique. Chaque g\u00e2teau est soigneusement personnalis\u00e9 avec le pr\u00e9nom et l\u2019\u00e2ge de la personne c\u00e9l\u00e9br\u00e9e.\n\n\ud83d\udc9d Ce g\u00e2teau apportera une touche de f\u00e9erie et de raffinement \u00e0 votre f\u00eate, pour des souvenirs aussi doux qu'inoubliables!" },
            { src: "482f456a-4982-477f-9980-047c6db2a8e9_w.jpg", title: "Ballerine Paris", description: "\u2728 Laissez-vous transporter dans un ballet gustatif avec ce g\u00e2teau qui c\u00e9l\u00e8bre l'amour de la danse avec une douceur po\u00e9tique. Parfait pour les c\u0153urs qui battent au rythme des entrechats et des pirouettes, il est couronn\u00e9 de chaussons de danse en fondant, invitant \u00e0 un pas de deux gourmand.\n\n\ud83c\udf38 Des c\u0153urs fondants d'un rose tendre se blottissent autour de ce g\u00e2teau, comme une ronde d'affection autour d'une sc\u00e8ne de ballet. Et l\u00e0, sur sa sc\u00e8ne de cr\u00e8me, une ballerine esquisse une arabesque devant la silhouette romantique de la Tour Eiffel, sa jupe rouge en relief \u00e9voquant les tutus virevoltants des grandes sc\u00e8nes.\n\n\ud83d\udc8c Le pr\u00e9nom de l'heureux destinataire s'\u00e9crit avec gr\u00e2ce sur un ruban rose, \u00e9voquant les d\u00e9licats lacets des chaussons qui s'enlacent \u00e0 la cheville des danseuses. Offrez ce g\u00e2teau comme on offre une rose, un geste tendre pour un anniversaire empreint de r\u00eaverie et de passion pour la danse." },
            { src: "91a774af-677d-48cb-9fb3-14a4f39b872a_w.jpg", title: "BBQ", description: "\ud83e\udd69\u2668\ufe0fCe g\u00e2teau BBQ est parfait pour les amateurs de grillades et les f\u00eates estivales ! \ud83c\udf89 Le dessus du g\u00e2teau est d\u00e9cor\u00e9 pour ressembler \u00e0 un vrai barbecue, avec des brochettes, des saucisses et des morceaux de viande en fondant. C'est le choix id\u00e9al pour surprendre une personne passionn\u00e9e de barbecue ou pour c\u00e9l\u00e9brer un anniversaire estival avec des amis. \n\n\ud83e\uddfa\ud83e\udd73Que ce soit pour une garden-party, un pique-nique ou une f\u00eate de famille, ce g\u00e2teau saura ravir les yeux et les papilles de tous les invit\u00e9s.\n\n\ud83c\udf82\ud83c\udf70 N'h\u00e9sitez pas \u00e0 personnaliser ce g\u00e2teau avec un message ou des d\u00e9corations suppl\u00e9mentaires pour rendre la f\u00eate encore plus sp\u00e9ciale. Chaque d\u00e9tail est soigneusement r\u00e9alis\u00e9 pour garantir une pr\u00e9sentation impeccable et un go\u00fbt d\u00e9licieux. " },
            { src: "390629f1-a31a-4b04-a81a-25476218b470_w.jpg", title: "B\u00e9b\u00e9 Dinosaure", description: "\ud83e\uddc1 G\u00e2teau Premier Anniversaire 'Dino \u00c9veil' \ud83e\udd95\n\nC\u00e9l\u00e9brez le tout premier anniversaire de votre petit tr\u00e9sor avec notre charmant g\u00e2teau 'Dino \u00c9veil'. Une cr\u00e9ation gourmande qui \u00e9merveillera les yeux avant de ravir les papilles. Ce smash cake est couronn\u00e9 d'un adorable b\u00e9b\u00e9 dinosaure \u00e9mergeant de sa coquille, m\u00e9ticuleusement model\u00e9 en fondant.\n\n\ud83c\udf43 Nature et Douceur \ud83c\udf3f\n\nLe tour du g\u00e2teau est orn\u00e9 de feuilles en fondant, apportant une touche de nature et de douceur. La cr\u00e8me au beurre, d'une texture d\u00e9licate, est travaill\u00e9e avec soin pour imiter de petites feuilles, cr\u00e9ant ainsi un effet visuel \u00e9l\u00e9gant et subtil.\n\n\ud83d\udc76 Personnalisation \ud83c\udf88\n\nSur le devant du g\u00e2teau, le pr\u00e9nom de votre enfant s'inscrit avec tendresse, accompagn\u00e9 d'un grand chiffre '1' symbolisant cette premi\u00e8re ann\u00e9e \u00e9coul\u00e9e. Chaque d\u00e9tail est pens\u00e9 pour rendre cet anniversaire aussi unique que votre bout de chou.\n\nN\u2019h\u00e9sitez pas \u00e0 partager la joie et la d\u00e9couverte avec ce g\u00e2teau sp\u00e9cialement con\u00e7u pour les petites mains curieuses. Un souvenir inoubliable et des photos craquantes garanties pour ce jour sp\u00e9cial !" },
            { src: "7e33487e-2361-4d44-bbc1-a6ddd7124a92_w.jpg", title: "B\u00e9b\u00e9 Minnie", description: "\ud83c\udf82 Voici notre g\u00e2teau B\u00e9b\u00e9 Minnie, cr\u00e9\u00e9 avec amour. Tellement cute qu'on aurait envie de lui faire un gros calin ! \ud83e\udd17\n\n\ud83c\udf80 Nous avons mis tout notre c\u0153ur dans la cr\u00e9ation de ce g\u00e2teau, inspir\u00e9 du sourire joyeux de Minnie et de son c\u00e9l\u00e8bre n\u0153ud rouge. Ce dessert est con\u00e7u pour faire briller les yeux des enfants... et des parents. \ud83d\udcf8" },
            { src: "207d22c1-7cf8-4a20-8a4c-f4c974a96dbf_w.jpg", title: "Bo\u00eete Plaisir d'Occasion", description: "\u2728 C\u00e9l\u00e9brez les moments sp\u00e9ciaux avec notre magnifique Bo\u00eete Plaisir d'Occasion ! Compos\u00e9e de 8 cupcakes savoureux et d'un g\u00e2teau de 6 pouces, ce duo est sublim\u00e9 par notre d\u00e9licieuse cr\u00e8me au beurre, faite \u00e0 100% \u00e0 base de vrai beurre. Les d\u00e9licats tourbillons de cr\u00e8me, parsem\u00e9s de perles dor\u00e9es et orn\u00e9s de papillons en sucre finement d\u00e9taill\u00e9s, apportent une touche d\u2019\u00e9l\u00e9gance \u00e0 vos f\u00eates. Que ce soit pour un anniversaire ou une attention sp\u00e9ciale, nous adaptons les couleurs et les d\u00e9tails selon vos envies pour offrir un dessert unique et personnalis\u00e9 qui impressionnera vos invit\u00e9s ! \ud83c\udf80\ud83d\udc96\n\nhttps://jomiepatisserie.com/products/boite-plaisir-doccasion" },
            { src: "7660354d-af10-4146-b656-1b1eb1b88846_w.jpg", title: "Bricoleur 2", description: "\ud83d\udd28 Mettez \u00e0 l'honneur l'habilet\u00e9 et la cr\u00e9ativit\u00e9 avec notre G\u00e2teau \"Mains Habiles\" ! Id\u00e9al pour l'artisan dans l'\u00e2me ou l'ami qui aime bricoler, ce g\u00e2teau est un hommage aux b\u00e2tisseurs et aux cr\u00e9ateurs. Avec ses outils model\u00e9s en fondant, de la cl\u00e9 anglaise aux tournevis, chaque d\u00e9tail est pens\u00e9 pour refl\u00e9ter la passion du travail manuel.\n\n\ud83d\udccf Encerclant la base du g\u00e2teau, un galon \u00e0 mesurer jaune en fondant, symbole de pr\u00e9cision, ajoutant une note de couleur et de r\u00e9alisme.\n\n\ud83e\udeb5 En vedette sur le devant, une planche de bois en fondant, o\u00f9 le pr\u00e9nom et l'\u00e2ge de la personne f\u00eat\u00e9e sont soigneusement inscrits en lettre d'or." },
            { src: "80519080-ce3e-4bf0-b346-9d4a9f814e66_w.jpg", title: "Caramel et Caf\u00e9", description: "\u2615 Nouvelle cr\u00e9ation : d\u00e9couvrez notre g\u00e2teau \u00e0 la chantilly caf\u00e9, caramel et biscuits Biscoff.\n\n\ud83c\udf70 Ce dessert allie la l\u00e9g\u00e8ret\u00e9 d'une chantilly au caf\u00e9 et la richesse d'une couche de caramel fleur de sel. Une couche additionnelle de morceaux de biscuits Biscoff ajoute une texture croustillante et savoureuse.\n\n\ud83c\udf6a Sur le dessus du g\u00e2teau, une poudre de biscuits Biscoff et des grains de caf\u00e9 croquants apportent une touche visuelle et gustative unique. \ud83d\ude0d" },
            { src: "25795e07-41ef-4a33-9ab5-569e59f17e45_w.jpg", title: "Champagne et \u00c9l\u00e9gance", description: "\ud83c\udf82 D\u00e9couvrez notre sublime g\u00e2teau noir et or, sublim\u00e9 par de magnifiques roses rouges et accompagn\u00e9 d'une bouteille de champagne Veuve Clicquot. Un choix parfait pour c\u00e9l\u00e9brer un anniversaire plein de charme, ce g\u00e2teau est id\u00e9al pour exprimer votre affection de mani\u00e8re \u00e9l\u00e9gante et sophistiqu\u00e9e.\n\n\ud83c\udf39 Ses d\u00e9tails dor\u00e9s et raffin\u00e9s en font un choix privil\u00e9gi\u00e9 pour les amoureux souhaitant marquer une occasion sp\u00e9ciale ou toute personne cherchant \u00e0 offrir un cadeau m\u00e9morable et raffin\u00e9. Ce g\u00e2teau incarne un \u00e9quilibre parfait entre douceur et \u00e9l\u00e9gance, id\u00e9al pour une c\u00e9l\u00e9bration intime ou un moment de partage avec des proches." },
            { src: "0630b7c6-423f-4af4-82e0-fa344cb0f81a_w.jpg", title: "Chat Fancy", description: "\ud83d\udc08\u200d\u2b1b\ud83d\udc8e Pour les amoureux des chats : d\u00e9couvrez notre g\u00e2teau repr\u00e9sentant l'\u00e9legance et le raffinement d'une f\u00e9line. Les yeux sont peints \u00e0 la main, la truffe rose est en fondant et les moustaches en papier parchemin. Une v\u00e9ritable douceur visuelle et gustative !\n\n\ud83c\udf38 Sur le dessus du g\u00e2teau, un parterre de fleurs bleues en fondant s'allie \u00e0 de charmantes fleurs blanches, \u00e9voquant la d\u00e9licatesse et la beaut\u00e9.\n\n\ud83d\udcff Un collier de perles blanches en fondant et de perles bleues en sucre entourent le g\u00e2teau." },
            { src: "88a2f218-2701-466e-a98a-7b61b80a5e98_w.jpg", title: "Chat Fancy 2", description: "\ud83c\udf82 Voici notre g\u00e2teau au charmant minois de chat! Chaque d\u00e9tail a \u00e9t\u00e9 soigneusement pens\u00e9 pour que ce g\u00e2teau devienne la pi\u00e8ce ma\u00eetresse de vos c\u00e9l\u00e9brations.\n\n\ud83c\udf1f\ud83d\udc08\u200d\u2b1b Ses petites \u00e9toiles dor\u00e9es, sa couronne \u00e9l\u00e9gante, ses yeux noirs scintillants, et son design irr\u00e9sistible de chat apporteront \u00e0 coup s\u00fbr une touche de magie \u00e0 votre journ\u00e9e. Nous avons cr\u00e9\u00e9 ce g\u00e2teau pour rendre vos moments encore plus m\u00e9morables, tout en douceur et en d\u00e9licatesse. \ud83d\ude3b" },
            { src: "f5158809-a71a-46eb-abb8-419531b05d60_w.jpg", title: "Chat Mignon 1", description: "\ud83c\udf80 Un g\u00e2teau tendre et rempli de douceur, d\u00e9cor\u00e9 d\u2019un adorable petit chat. Ses tons roses et ses petits c\u0153urs en font un choix charmant pour les amateurs de designs mignons et d\u00e9licats. \n\n\ud83d\udc95 Une cr\u00e9ation qui fera le bonheur des enfants et touchera aussi les adultes. Que ce soit pour c\u00e9l\u00e9brer l\u2019anniversaire d\u2019une petite fille passionn\u00e9e par les chats, pour offrir un cadeau affectueux \u00e0 une maman, ou pour toute autre occasion sp\u00e9ciale, ce g\u00e2teau apportera une touche de tendresse. \n\n\ud83c\udf38 Personnalisable selon vos envies, il combine esth\u00e9tisme et saveurs gourmandes, cr\u00e9ant un souvenir m\u00e9morable aussi beau que d\u00e9licieux." },
            { src: "acff748a-df6d-4df4-9c3f-78a42ba40f82_w.jpg", title: "Chateau de Princesses", description: "\ud83c\udff0\u2728 Faites briller les yeux de votre petite princesse avec ce g\u00e2teau ch\u00e2teau enchant\u00e9, notre premi\u00e8re cr\u00e9ation de ce type. Con\u00e7u sp\u00e9cialement pour les anniversaires, il est d\u00e9cor\u00e9 de toutes ses princesses pr\u00e9f\u00e9r\u00e9es qui ajoutent une touche de magie \u00e0 la f\u00eate.\n\n\ud83c\udf70\ud83d\udc96 Nous avons mis tout notre c\u0153ur et notre souci du d\u00e9tail dans ce g\u00e2teau unique. Chaque texture est soigneusement travaill\u00e9e et chaque princesse est s\u00e9lectionn\u00e9e pour un tout coh\u00e9rent et capturer l'imaginaire de votre enfant. Les petites fleurs d\u00e9licates, le portail sculpt\u00e9 et les douces nuances de couleurs refl\u00e8tent notre engagement \u00e0 cr\u00e9er un g\u00e2teau aussi beau que charmant.\n\n\ud83c\udf82\ud83c\udf1f Offrez \u00e0 votre enfant un anniversaire f\u00e9erique avec notre premier g\u00e2teau en forme de ch\u00e2teau." },
            { src: "f7318c4d-0256-477c-a1d3-0d27410f4687_w.jpg", title: "Citrons", description: "\ud83c\udf3f D\u00e9couvrez notre g\u00e2teau compl\u00e8tement citron\u00e9 \u00e0 deux \u00e9tages, parfait pour les amateurs de fra\u00eecheur. Avec ses rondelles de citron et ses feuilles vertes, il \u00e9voque les journ\u00e9es ensoleill\u00e9es et les moments de d\u00e9tente. Une d\u00e9coration simple qui charme par son naturel.\n\n\ud83c\udf4b La douceur du citron frais et de la cr\u00e8me au beurre se marie id\u00e9alement pour des moments gourmands en famille ou entre amis. Les cake pops assortis, mignons et d\u00e9licieux, plairont aux petits comme aux grands. Ce g\u00e2teau au citron est un choix rafra\u00eechissant et gourmand, id\u00e9al pour un anniversaire ou une f\u00eate estivale." },
            { src: "2dd05d98-fdff-46d3-a003-4ffe5ff6b232_w.jpg", title: "Coeur 23", description: "\ud83c\udf70 Un classique... avec une petite surprise au milieu ! Ce g\u00e2teau en forme de c\u0153ur, tout en volutes roses et perles \u00e9l\u00e9gantes, a ce charme intemporel qui fait toujours plaisir. Mais si on s'approche un peu, on d\u00e9couvre un message qui ne manque pas de piquant \u2014 parce que parfois, f\u00eater un anniversaire, c\u2019est aussi l\u2019occasion de rigoler un bon coup avec une amie qu\u2019on adore.\n\n\ud83c\udf89 Pour celle qui aime les clins d'\u0153il dr\u00f4les autant que les belles attentions, ce g\u00e2teau m\u00e9lange tendresse et humour, avec ce petit \"twist\" qui rend le moment encore plus complice. On l\u2019imagine tr\u00e8s bien sur une table entour\u00e9e de rires et de sourires sinc\u00e8res.\n\n\ud83c\udf53 Et bien s\u00fbr, sous ce gla\u00e7age tout doux : un g\u00e2teau moelleux et savoureux, \u00e0 partager sans mod\u00e9ration. Parce qu'au final, ce qu\u2019on veut vraiment, c\u2019est passer un bon moment avec les gens qu\u2019on aime.\n\n\ud83d\udc49 Et toi, \u00e0 qui tu penserais offrir ce genre de g\u00e2teau ? \ud83d\udc96" },
            { src: "5a8fcb6a-2571-4479-bc3e-47a71e285f6f_w.jpg", title: "Coeur Amoureux", description: "\u2764\ufe0f Vous cherchez une fa\u00e7on douce et originale de dire \"je t\u2019aime\" ou \"joyeux anniversaire\" ? Voici notre g\u00e2teau en forme de c\u0153ur, pr\u00eat \u00e0 faire chavirer les c\u0153urs et ravir les papilles ! Avec ses nuances de rose et de rouge, et son message personnalis\u00e9, il fera briller les yeux de la personne que vous aimez, que ce soit un(e) partenaire, un(e) ami(e) ou m\u00eame un parent.\n\n\ud83c\udf82 Enti\u00e8rement recouvert d\u2019une cr\u00e8me au beurre onctueuse et d\u00e9cor\u00e9 de petits c\u0153urs, ce g\u00e2teau est parfait pour un moment complice et gourmand. \u00c0 partager en amoureux, en famille ou entre amis pour une c\u00e9l\u00e9bration pleine de douceur et de tendresse.\n\n\u2728 Vous pouvez m\u00eame y inscrire le pr\u00e9nom ou un mot doux pour rendre la surprise encore plus unique... et inoubliable \ud83d\udc8c\n\n\ud83d\udce9 Dites-nous pour qui il est, et on s\u2019occupe de la magie sucr\u00e9e \u2728" },
            { src: "022e6bba-80fc-46f9-99c7-0d1e2f746125_w.jpg", title: "Coeur Calendrier", description: "\ud83d\udcc5 Et si un g\u00e2teau pouvait cacher un petit secret ? Celui-ci d\u00e9voile un calendrier en papier magique : il suffit de le br\u00fbler pour faire appara\u00eetre un message. Un geste doux, symbolique, qui cr\u00e9e un moment complice \u00e0 partager.\n\n\ud83e\udd8b Avec sa forme de c\u0153ur, ses papillons dor\u00e9s et ses perles sucr\u00e9es, il attire les regards et touche les c\u0153urs. Que ce soit pour un amoureux, un parent, un ami proche\u2026 il transmet plus qu\u2019un simple \u201cje t\u2019aime\u201d, il cr\u00e9e un souvenir.\n\n\ud83c\udf81 Ce n\u2019est pas juste un g\u00e2teau, c\u2019est une surprise \u00e0 vivre. Un instant suspendu, une attention pleine de chaleur, un sourire qu\u2019on n\u2019oublie pas." },
            { src: "219a0737-a4b7-421a-97aa-0cbac3c97144_w.jpg", title: "Coeur Cerises", description: "\ud83c\udf52 Un g\u00e2teau en forme de c\u0153ur, tout en douceur et en tendresse, pour c\u00e9l\u00e9brer ceux qu'on aime\u202f! Avec ses cerises confites et ses d\u00e9tails d\u00e9licats, il a ce petit quelque chose qui fait sourire d\u00e8s qu\u2019on le pose sur la table.\n\n\ud83c\udf82 Ses teintes ros\u00e9es et son look r\u00e9tro chic en font le complice parfait pour f\u00eater un 18e anniversaire, une d\u00e9claration d'amour, ou encore une surprise pour un parent, une amie ou une personne ch\u00e8re. Que ce soit pour dire \"je t'aime\" ou \"merci d'\u00eatre l\u00e0\", ce g\u00e2teau fait passer le message sans m\u00eame dire un mot.\n\n\u2728 On adore le voir illuminer un repas en famille ou une f\u00eate entre proches, toujours avec cette petite touche nostalgique qui rappelle les beaux moments partag\u00e9s." },
            { src: "59e3ea8f-5c6b-4b9f-8993-8cb1ded0aa5c_w.jpg", title: "Coeur Choco", description: "\ud83c\udf6b Ce g\u00e2teau en forme de c\u0153ur, enti\u00e8rement chocolat, est une fa\u00e7on douce et simple de marquer un moment sp\u00e9cial. Son look sobre et soign\u00e9 en fait une belle surprise, que ce soit pour un anniversaire, une attention romantique ou un geste tendre envers un proche.  \n\n\ud83d\udc9b Avec ses teintes chaudes et ses petites perles dor\u00e9es, il \u00e9voque une affection sinc\u00e8re, sans extravagance. Un cadeau qui fait sourire, que l'on soit un enfant qui veut faire plaisir \u00e0 un parent, un amoureux qui veut toucher l\u2019autre en douceur, ou un ami qui veut souligner une \u00e9tape importante.  \n\n\ud83c\udf82 Facile \u00e0 partager autour d\u2019un caf\u00e9 ou d\u2019un souper tranquille, il s\u2019invite naturellement dans ces moments o\u00f9 l'on veut dire \"tu comptes pour moi\" sans trop de mots." },
            { src: "291b5c78-01e2-449f-81b8-bc59ec4d8d1d_w.jpg", title: "Coeur Floral", description: "\ud83d\udc96 Voici notre g\u00e2teau en forme de c\u0153ur, un petit bijou de tendresse et de douceur. Avec ses fleurs d\u00e9licates et ses perles comestibles, il est parfait pour faire plaisir \u00e0 une personne ch\u00e8re : une maman, une amie, une s\u0153ur ou m\u00eame l'amour de votre vie. Parce qu'il y a mille occasions de dire \"je t\u2019aime\" ou \"je pense \u00e0 toi\".\n\n\ud83c\udf38 On adore ses tons cr\u00e8me et ses fleurs pastel qui lui donnent un air po\u00e9tique et \u00e9l\u00e9gant. Que ce soit pour un anniversaire, une f\u00eate sp\u00e9ciale ou juste pour offrir un moment sucr\u00e9 \u00e0 quelqu\u2019un qui le m\u00e9rite, ce g\u00e2teau fait toujours son petit effet.\n\n\ud83c\udf82 Et parce que chaque geste compte, vous pouvez y ajouter un pr\u00e9nom ou un message personnalis\u00e9 pour rendre ce moment unique. Un cadeau qui pla\u00eet autant aux grands qu'aux plus jeunes, pour ceux qui aiment les choses belles et simples.\n\n\u2728 Dites-nous en commentaire \u00e0 qui vous aimeriez offrir ce g\u00e2teau ! \ud83d\udcac\ud83d\udc47" },
            { src: "b5e67356-0ef6-45be-9091-b40515948b1d_w.jpg", title: "Coeur Passion", description: "\ud83c\udf82\ud83e\udd0d\ud83e\ude77 Craquez pour notre collection de g\u00e2teaux en forme de c\u0153ur ! Parfaits pour apporter une touche d'amour \u00e0 vos moments importants, que ce soit un anniversaire, une surprise romantique, ou juste un geste tendre pour vos proches. Nos g\u00e2teaux sont joliment d\u00e9cor\u00e9s avec des d\u00e9tails soigneux et des couleurs douces, pens\u00e9s pour ravir petits et grands \u00e0 chaque bouch\u00e9e. \ud83d\udc95 \n\nOffrez un petit bonheur sucr\u00e9 qui parle d\u2019amour sans dire un mot ! \ud83d\udc9d" },
            { src: "86906c3d-9cc7-409a-8d09-8423ac077349_w.jpg", title: "Coeur Pastel", description: "Offrez une touche d\u2019humour \ud83d\ude04 et de tendresse \ud83d\udc96 avec notre g\u00e2teau c\u0153ur \"Too Old for Leo\", une cr\u00e9ation originale et personnalis\u00e9e \ud83c\udf82, parfaite pour c\u00e9l\u00e9brer un anniversaire \ud83c\udf89, une occasion sp\u00e9ciale \u2728 ou simplement surprendre un proche \ud83c\udf81.\n\nInspir\u00e9 du style r\u00e9tro vintage \ud83c\udf80, ce g\u00e2teau unique se pare de teintes bleu pastel \ud83d\udc99 et rose bonbon \ud83c\udf38, d\u00e9cor\u00e9 de perles comestibles nacr\u00e9es \u26aa et de petites fleurs d\u00e9licates en sucre \ud83c\udf3c, le tout sur une base moelleuse et gourmande \ud83c\udf70. Un g\u00e2teau qui fera sourire autant qu\u2019il r\u00e9galera\u202f! \ud83d\ude0d" },
            { src: "a0ede009-273e-4daa-8f28-0d9298d1e3d3_w.jpg", title: "Coeur Queen", description: "\u2728 Un c\u0153ur tout doux pour c\u00e9l\u00e9brer une \"Birthday Queen\" comme il se doit ! Ce g\u00e2teau tendre et ros\u00e9 est l\u00e0 pour dire \"je pense \u00e0 toi\" d'une fa\u00e7on simple et \u00e9l\u00e9gante. Que ce soit pour maman, une s\u0153ur, une amie, ou quelqu'un de sp\u00e9cial, il fera fondre les c\u0153urs avec son style d\u00e9licat et son message affectueux. \ud83d\udc97\n\n\ud83d\udcab On adore ses petites perles nacr\u00e9es et sa bordure en cr\u00e8me, travaill\u00e9e juste ce qu\u2019il faut pour lui donner un charme unique. Un choix parfait si tu veux offrir un moment sucr\u00e9 et touchant, sans en faire trop, juste ce qu'il faut pour cr\u00e9er un joli souvenir.\n\n\ud83c\udf89 Et le petit plus : le message est 100% personnalisable ! Tu veux dire \"Je t\u2019aime\", \"Merci\", ou \"Joyeux anniversaire\" ? Dis-nous, et on s\u2019occupe du reste. \ud83d\udc8c" },
            { src: "5709517f-6e20-4d12-9ea2-6f403e530de4_w.jpg", title: "Collide With The Sky", description: "\ud83c\udf70 Inspir\u00e9 de l'album embl\u00e9matique \"Collide With the Sky\" de Pierce The Veil, ce g\u00e2teau est une repr\u00e9sentation fid\u00e8le de la couverture de l'album. La maison, tir\u00e9e de la pochette de l'album, est soigneusement imprim\u00e9e sur du papier comestible et habille avec \u00e9l\u00e9gance la fa\u00e7ade du g\u00e2teau.\n\n\ud83c\udfa8 Le personnage iconique de l'album se dresse fi\u00e8rement sur le sommet, cr\u00e9ant un effet 3D qui se poursuit jusque sur le plateau. Cette mise en sc\u00e8ne est sublim\u00e9e par le logo distinctif de Pierce The Veil, tenu par deux piques en bois. De plus, pour ceux qui se laisseraient tenter par un tour complet de cette cr\u00e9ation, ils d\u00e9couvriront un travail de peinture \u00e0 la main fid\u00e8le \u00e0 l'ambiance de la couverture de l'album.\n\n\ud83c\udf81 Parfait pour surprendre un fan du groupe ou tout simplement quelqu'un appr\u00e9ciant le rock alternatif, ce g\u00e2teau porte \u00e9galement une touche personnelle : le pr\u00e9nom de l'heureux destinataire est d\u00e9licatement inscrit en fondant sur le dessus." },
            { src: "1ecd3573-6390-4209-956b-f607a1d703e8_w.jpg", title: "Combo Cupcakes - Ourson 1", description: "\u2728 Un premier anniversaire, \u00e7a se f\u00eate en beaut\u00e9... et en douceur ! \ud83c\udf89 D\u00e9couvrez notre combo g\u00e2teau et cupcakes assortis, d\u00e9cor\u00e9 sur le th\u00e8me ourson pour une f\u00eate pleine de tendresse. Ce petit g\u00e2teau tout blanc, rehauss\u00e9 de billes en fondant chocolat et bleu, cache un adorable ourson en sucre qui veille sur cette journ\u00e9e si sp\u00e9ciale. \ud83d\udc3b\ud83d\udc9b\n\n\ud83e\uddc1 Et pour que la f\u00eate soit compl\u00e8te, six jolis cupcakes d\u00e9cor\u00e9s dans les m\u00eames tons chocolat et bleu viennent accompagner le g\u00e2teau. De quoi ravir les invit\u00e9s, petits et grands, et faire de ce moment un souvenir sucr\u00e9 et gourmand. \u2728 Une attention parfaite pour c\u00e9l\u00e9brer les premiers pas d\u2019un bout de chou, ou pour offrir aux jeunes parents qui vivent ce moment unique.\n\n\ud83c\udf88 Vous aussi, vous imaginez une f\u00eate toute douce pour un premier anniversaire ? Contactez-nous pour cr\u00e9er une version sur mesure, pleine de tendresse et de saveurs. \ud83d\udcac\ud83c\udf70" },
            { src: "a44b7518-54dd-474e-a36b-d77d503e8841_w.jpg", title: "Combo Smash Cake - Animaux 1", description: "\u270c\ufe0fD\u00e9couvrez nos g\u00e2teaux Combo Smash Cake, comprenant un g\u00e2teau principal pour surprendre et ravir vos invit\u00e9s et accompagn\u00e9 de son petit \"smash cake\" assorti fait sp\u00e9cialement pour b\u00e9b\u00e9 ! Une invitation \u00e0 explorer, toucher et go\u00fbter, tout en s\u2019amusant pour \u00e9veiller ses sens. \ud83c\udf1f" },
            { src: "618b483e-672a-4edd-a1b0-c15fa33c332d_w.jpg", title: "Combo Smash Cake - Bandes 1", description: "\ud83c\udf82 Ce g\u00e2teau \u00e9l\u00e9gant, accompagn\u00e9 de sa version miniature, c\u00e9l\u00e8bre avec douceur les premiers pas dans la vie de votre enfant. Des bandes de fondant d\u00e9licatement dispos\u00e9es en d\u00e9grad\u00e9 habillent ces deux pi\u00e8ces, \u00e9voquant la tendresse et la croissance.\n\n\ud83e\udd0d Au sommet, des boules de fondant et d\u00e9coratives ajoutent une touche ludique, rappelant les jeux d'enfance. Le chiffre \"1\" en fondant symbolise cette premi\u00e8re ann\u00e9e m\u00e9morable. B\u00e9b\u00e9 a aussi le droit \u00e0 son chiffre \"1\" fait en fondant textur\u00e9.\n\n\u2728 Pour finir nous avons ajout\u00e9 de petites perles dor\u00e9es en sucre parsem\u00e9es avec d\u00e9licatesse. " },
            { src: "78b25805-b794-4dfe-918d-809c7ad708e8_w.jpg", title: "Combo Smash Cake - Cupcakes 1", description: "\ud83e\udd8b D\u00e9couvrez notre g\u00e2teau g\u00e9ant en forme de cupcake et son mini g\u00e2teau assorti, issus de notre collection Combo, pour apporter une douce touche de magie \u00e0 vos c\u00e9l\u00e9brations. Avec son harmonie de rose pastel et ses d\u00e9tails dor\u00e9s, ses roses de cr\u00e8me d\u00e9licatement travaill\u00e9es, ses perles et ses papillons, ce duo cr\u00e9e une ambiance po\u00e9tique et \u00e9l\u00e9gante, parfaite pour les premiers anniversaires et les moments m\u00e9morables en famille.\n\n\ud83c\udf70 Le grand g\u00e2teau fait sensation sur la table, tandis que le mini smash cake est con\u00e7u pour ravir les petites mains curieuses. Ce g\u00e2teau unique transforme chaque instant en souvenir pr\u00e9cieux et sublime vos photos de f\u00eate avec son style enchanteur." },
            { src: "5e75caf6-dba1-4f09-b7ae-8f6cd3c3003a_w.jpg", title: "Combo Smash Cake - F\u00e9es 1", description: "\ud83c\udf44 Voici notre derni\u00e8re cr\u00e9ation dans la collection Combo Smash Cake\u202f: un g\u00e2teau f\u00e9erique con\u00e7u avec soin pour c\u00e9l\u00e9brer le tout premier anniversaire de votre petit tr\u00e9sor. La pi\u00e8ce ma\u00eetresse, une grande maison champignon accueillante, est d\u00e9cor\u00e9e d\u2019une adorable f\u00e9e qui ressemble \u00e0 b\u00e9b\u00e9, un d\u00e9tail pens\u00e9 pour rendre ce moment encore plus unique et personnalis\u00e9. \u00c0 ses c\u00f4t\u00e9s, le petit smash cake est d\u00e9cor\u00e9 d\u2019une petite f\u00e9e avec un arrosoir, ajoutant une touche ludique et pleine de tendresse qui invite b\u00e9b\u00e9 \u00e0 explorer et \u00e0 s\u2019amuser\u202f!\n\n\ud83e\uddda\u200d\u2640\ufe0f Ce g\u00e2teau, c\u2019est une c\u00e9l\u00e9bration de l\u2019\u00e9merveillement et de l\u2019amour\u202f: il capte l\u2019innocence de b\u00e9b\u00e9 tout en touchant le c\u0153ur des parents. Les teintes pastel, les fleurs d\u00e9licates et les papillons ajoutent une ambiance douce et enchant\u00e9e \u00e0 cette journ\u00e9e si sp\u00e9ciale. Ce duo unique transforme le premier anniversaire en un souvenir pr\u00e9cieux, plein de magie et de complicit\u00e9 en famille.\n" },
            { src: "79a7c9aa-e83f-4331-afa7-1bdc718545e6_w.jpg", title: "Combo Smash Cake - Fraises 1", description: "\ud83c\udf53 Ce duo de g\u00e2teaux est fait pour c\u00e9l\u00e9brer avec tendresse le tout premier anniversaire de b\u00e9b\u00e9 ! Un grand g\u00e2teau d\u00e9licatement d\u00e9cor\u00e9 de fraises et de fleurs, accompagn\u00e9 de son petit smash cake, parfait pour que b\u00e9b\u00e9 d\u00e9couvre le plaisir de go\u00fbter avec ses mains, cr\u00e9ant un moment amusant et inoubliable. Les invit\u00e9s pourront savourer le grand g\u00e2teau pendant que b\u00e9b\u00e9 se r\u00e9gale avec le sien, dans une ambiance douce et joyeuse.\n\n\ud83d\udd90\ufe0f Ce combo est con\u00e7u pour cr\u00e9er de beaux souvenirs \u00e0 immortaliser en famille, alliant gourmandise et moments complices. Un cadeau aussi mignon que savoureux, qui fera fondre les c\u0153urs et les papilles !\n" },
            { src: "5a67df6c-69ff-4742-a5eb-998b6d6ebe62_w.jpg", title: "Combo Smash Cake - Jungle 1", description: "\ud83e\udd81 Ce g\u00e2teau double format est con\u00e7u pour apporter un moment unique \u00e0 votre c\u00e9l\u00e9bration ! Avec son grand g\u00e2teau color\u00e9 et son adorable smash cake sp\u00e9cialement pens\u00e9 pour b\u00e9b\u00e9, il cr\u00e9e une atmosph\u00e8re amusante et douce. Ses personnages mignons comme le lion, le singe, et l\u2019\u00e9l\u00e9phant, tous coiff\u00e9s de petits chapeaux de f\u00eate, ajoutent une touche de tendresse irr\u00e9sistible.\n\n\ud83c\udf89 Le grand g\u00e2teau attire imm\u00e9diatement l\u2019attention avec ses d\u00e9tails inspir\u00e9s de la jungle, parfait pour que tout le monde puisse en profiter, tandis que le smash cake invite b\u00e9b\u00e9 \u00e0 s\u2019amuser et \u00e0 explorer, les petites mains pr\u00eates \u00e0 plonger dans la cr\u00e8me. Un moment de d\u00e9couverte qui fera sourire toute la famille !" },
            { src: "6527204f-7171-41ec-9615-e910a49a8175_w.jpg", title: "Combo Smash Cake - Ourson 1", description: "\ud83e\uddf8 Pour le tout premier anniversaire de b\u00e9b\u00e9, ce g\u00e2teau charmant est une fa\u00e7on douce de marquer l'occasion. Un adorable ourson en fondant, entour\u00e9 de petites \u00e9toiles dor\u00e9es et de nuages, cr\u00e9e une ambiance magique et chaleureuse. Avec ses couleurs bleu ciel et blanc, cette cr\u00e9ation inspire la tendresse et la joie de vivre de cet \u00e2ge pr\u00e9cieux. \ud83c\udf82\n\n\ud83c\udf1f Le g\u00e2teau principal est accompagn\u00e9 d'un petit \"smash cake\" assorti, juste pour b\u00e9b\u00e9 ! Une invitation \u00e0 explorer, toucher, et go\u00fbter, tout en s\u2019amusant. Les petits d\u00e9tails comme les ballons et les nuages donnent un effet a\u00e9rien et l\u00e9ger, parfait pour une f\u00eate simple et joyeuse en famille." },
            { src: "3f674bbd-8b09-49ce-b65d-a2b806115742_w.jpg", title: "Costume Chic", description: "Bonne Ann\u00e9e et bonne sant\u00e9 \u00e0 tous pour cette nouvelle ann\u00e9e 2025!\n\n\ud83c\udf89 En 2025, pr\u00e9parez vos f\u00eates avec style ! Ce g\u00e2teau festif, habill\u00e9 comme un costard avec un n\u0153ud papillon dor\u00e9, est bien plus qu\u2019un dessert : c\u2019est une pi\u00e8ce ma\u00eetresse qui attire les regards et enchante les c\u0153urs. Parfait pour les soir\u00e9es \u00e9l\u00e9gantes et les moments de partage, il c\u00e9l\u00e8bre avec raffinement la fin de l\u2019ann\u00e9e et le d\u00e9but d\u2019une nouvelle aventure. \ud83c\udfa9\u2728\n\n\ud83d\udc96 Une surprise gourmande qui plaira \u00e0 tous : qu\u2019il soit destin\u00e9 \u00e0 un papa chic, un ami pr\u00e9cieux ou une personne qui m\u00e9rite une attention sp\u00e9ciale, ce g\u00e2teau est une mani\u00e8re d\u00e9licate et savoureuse de marquer l\u2019instant. Imaginez-le illuminer votre table de r\u00e9veillon et offrir un souvenir m\u00e9morable \u00e0 vos proches. \ud83c\udf70" },
            { src: "07c79731-41cd-4d63-8601-9a56f64acc50_w.jpg", title: "Couteau Stephen King", description: "\ud83c\udf82 Pour les v\u00e9ritables fans de Stephen King, ce g\u00e2teau est un incontournable ! Avec sa confiture rouge sanglante et son couteau en fondant plant\u00e9 au sommet, il \u00e9voque parfaitement l'atmosph\u00e8re sombre et intense des romans du ma\u00eetre de l'horreur. Que vous organisiez une f\u00eate d'anniversaire ou une soir\u00e9e \u00e0 th\u00e8me, ce g\u00e2teau est le d\u00e9tail qui fera toute la diff\u00e9rence.\n\n\ud83d\udd2a Inspir\u00e9 par une sc\u00e8ne digne de \"Misery\", ce dessert est un clin d'\u0153il saisissant pour ceux qui aiment les histoires pleines de suspense et de frissons. Chaque d\u00e9tail de ce g\u00e2teau, de la texture lisse du gla\u00e7age \u00e0 la pr\u00e9cision du couteau en chocolat, est con\u00e7u pour capturer l'essence des \u0153uvres de King." },
            { src: "cc979b4c-a69d-441f-a4e6-c798415b7a1c_w.jpg", title: "Disco 70s 80s", description: "\ud83d\udd7a\u2728 Faites un bond dans le temps et illuminez votre f\u00eate avec notre g\u00e2teau Disco Dancers ! Parfait pour les fans de la fi\u00e8vre du samedi soir et l'\u00e8re disco, ce g\u00e2teau capturera l'essence de vos souvenirs pr\u00e9f\u00e9r\u00e9s des ann\u00e9es 70 et 80.\n\n\ud83c\udfb6 Chaque d\u00e9tail de ce g\u00e2teau est une ode \u00e0 l'\u00e9poque disco, avec ses d\u00e9corations vivantes en fondant : une boule \u00e0 facettes, une radio au style r\u00e9tro, un vinyle embl\u00e9matique et le message affectueux \"I love 70-80\". C'est le g\u00e2teau id\u00e9al pour ceux qui portent dans leur c\u0153ur les jours ensoleill\u00e9s de la discoth\u00e8que !\n\n\u2728\ud83c\udf88 Ajoutez une dimension stellaire \u00e0 votre \u00e9v\u00e9nement avec les \u00e9toiles en fondant qui surplombent le g\u00e2teau. Et pour couronner le tout, nous inscrivons le pr\u00e9nom de la star de la f\u00eate en lettres d'or sur le plateau, rendant chaque c\u00e9l\u00e9bration unique." },
            { src: "bffed78e-a7e4-4b91-881a-2ab82204ca63_w.jpg", title: "El\u00e9phante Mignonne", description: "\ud83e\uddf8 Impossible de ne pas craquer devant cette petite \u00e9l\u00e9phante en fondant, confortablement install\u00e9e au sommet de ce g\u00e2teau plein de tendresse. Avec ses grands yeux doux et sa couronne de fleurs, elle a d\u00e9j\u00e0 conquis bien des c\u0153urs ici. \ud83d\udc95\n\n\ud83c\udf38 Les fleurs en fondant aux teintes douces de rose et de cr\u00e8me descendent en cascade tout autour, comme un petit jardin en f\u00eate. Et que dire des su\u00e7ons transparents qui flottent d\u00e9licatement autour d\u2019elle ? On dirait des bulles de bonheur \u2728\n\n\ud83d\udc96 Ce g\u00e2teau a \u00e9t\u00e9 pens\u00e9 pour faire fondre les c\u0153urs. Et comme toujours avec nos cr\u00e9ations, tout peut \u00eatre personnalis\u00e9 : les couleurs, le style des fleurs, les d\u00e9tails\u2026 et bien s\u00fbr le pr\u00e9nom de votre petite merveille. Que ce soit pour un anniversaire, un bapt\u00eame ou un simple moment \u00e0 c\u00e9l\u00e9brer en douceur, on est l\u00e0 pour le rendre inoubliable \ud83d\udcab" },
            { src: "845ff31f-dfa7-495e-9ff1-f1d193949939_w.jpg", title: "Fillette et Ballons", description: "\ud83c\udf82 Voici notre g\u00e2teau pour c\u00e9l\u00e9brer l'anniversaire de votre enfant ! Ce g\u00e2teau unique pr\u00e9sente un grand chiffre en fondant rouge, d\u00e9cor\u00e9 de feuilles d'or, accompagn\u00e9 de ballons en rouge, blanc et dor\u00e9, \u00e9galement parsem\u00e9s de touches dor\u00e9es. Les ballons sont retenus par une adorable petite fille en robe rouge, ajoutant une touche de tendresse et de fantaisie.\n\n\ud83c\udf88 Le pr\u00e9nom de l'enfant est soigneusement inscrit en lettres dor\u00e9es sur le plateau recouvert de fondant blanc. De plus, la petite fille ou le petit gar\u00e7on peuvent \u00eatre personnalis\u00e9s pour ressembler \u00e0 la personne f\u00eat\u00e9e, rendant ce g\u00e2teau encore plus unique et sp\u00e9cial.\n\n\ud83d\udc67 Chaque d\u00e9tail de ce g\u00e2teau est con\u00e7u avec amour et soin, pour que votre petit tr\u00e9sor se sente vraiment sp\u00e9cial en ce jour unique. \ud83d\udc96" },
            { src: "c7c92b53-3ad2-4145-97b1-437ce7de543d_w.jpg", title: "Flammes Noires et Or", description: "\ud83c\udf70 D\u00e9couvrez notre g\u00e2teau classique au fondant blanc, rehauss\u00e9 de motifs \u00e9voquant des flammes noires, d\u00e9licatement juxtapos\u00e9es avec des touches \u00e9l\u00e9gantes de feuilles d'or comestible. L'\u00e9quilibre parfait entre modernit\u00e9 et tradition pour surprendre et ravir vos proches.\n\n\ud83c\udf38 Sur le sommet, un petit bouquet de plumes de pampa d'une douce teinte rose se m\u00e9lange harmonieusement avec de fines gypsophiles. Cette composition florale est accompagn\u00e9e de boules argent\u00e9es, dor\u00e9es et d'une boule noire en fondant, apportant une pointe de sophistication \u00e0 l'ensemble.\n\n\ud83c\udf89 Un topper \"Happy Birthday\" dor\u00e9 est piqu\u00e9 sur le dessus, et pour une touche personnelle, le pr\u00e9nom de l'heureux(se) \u00e9lu(e) est \u00e9l\u00e9gamment inscrit sur une bande de fondant, le tout en lettres dor\u00e9es. Une attention d\u00e9licate pour rendre ce jour inoubliable.\n\n\ud83c\udf81 \u00c0 offrir ou \u00e0 s'offrir, pour marquer une occasion sp\u00e9ciale avec \u00e9l\u00e9gance et raffinement." },
            { src: "9fca7dc1-6a79-4fbb-ab80-4109ef9c198a_w.jpg", title: "Fleurs Macarons 70", description: "\ud83c\udf70 D\u00e9couvrez la douceur incarn\u00e9e avec notre g\u00e2teau signature, baign\u00e9 dans des teintes de rose et de violet. Orn\u00e9e avec finesse de nos macarons faits maison et de fleurs artificielles s\u00e9lectionn\u00e9es, cette cr\u00e9ation est une promesse d'\u00e9l\u00e9gance pour vos c\u00e9l\u00e9brations.\n\n\ud83d\udc9b Imagin\u00e9 pour sublimer vos anniversaires, ce d\u00e9lice est couronn\u00e9 de chiffres \u00e9clatants, soigneusement saupoudr\u00e9s de poudre d'or alimentaire, apportant un \u00e9clat de luxe et de festivit\u00e9.\n\n\ud83c\udf3f Un design qui traverse le temps, notre g\u00e2teau est l'expression parfaite de vos \u00e9motions en ces occasions qui comptent, id\u00e9al pour manifester votre amour et votre appr\u00e9ciation envers vos proches." },
            { src: "081d7006-1fc4-431a-aedd-2f809ca45793_w.jpg", title: "Foxy Animatronic", description: "Amateurs et amatrices de frissons et de jeux vid\u00e9o, laissez-vous captiver par notre g\u00e2teau Foxy de Five Nights at Freddy's, une cr\u00e9ation gourmande qui saura surprendre et ravir. \ud83c\udfae\n\nCon\u00e7u avec soin pour capturer l'essence du c\u00e9l\u00e8bre animatronique pirate, ce g\u00e2teau est une c\u00e9l\u00e9bration des aventures nocturnes dans la pizzeria la plus effrayante des jeux vid\u00e9o. \ud83c\udff4\u200d\u2620\ufe0f\n\nSavourez l'harmonie entre l'esth\u00e9tique fid\u00e8le au personnage et la qualit\u00e9 de nos ingr\u00e9dients. C'est le choix parfait pour un anniversaire, une rencontre entre fans ou toute occasion sp\u00e9ciale o\u00f9 l'on souhaite allier passion pour le jeu vid\u00e9o, frissons et plaisir de la table. \ud83c\udf70\n" },
            { src: "8ab2cd00-96f6-4e6b-8c1f-2c8021628358_w.jpg", title: "G\u00e2teau Or\u00e9o Sal\u00e9/Sucr\u00e9", description: "\ud83c\udf82 Nouvelle cr\u00e9ation ! Notre g\u00e2teau Or\u00e9o revisit\u00e9 avec une touche de sel pour un \u00e9quilibre parfait entre le sucr\u00e9 et le sal\u00e9. Ce d\u00e9lice saura surprendre et ravir votre palais avec chaque bouch\u00e9e. Parfait pour les amateurs de douceurs l\u00e9g\u00e8res et \u00e9quilibr\u00e9es !\n" },
            { src: "c5faf415-5de1-4e11-a0e8-73e6e172664c_w.jpg", title: "Grenouille et Roseaux", description: "\ud83d\udc38 D\u00e9couvrez notre adorable g\u00e2teau sur le th\u00e8me des grenouilles, parfait pour f\u00eater le premier anniversaire de votre tout-petit ! Ce g\u00e2teau bleu pastel est orn\u00e9 d'une grenouille couronn\u00e9e, souriante et aux joues roses, qui saura faire craquer petits et grands. La grenouille est accompagn\u00e9e d'un mignon chiffre \"1\" en forme de grenouille pour marquer cette \u00e9tape si sp\u00e9ciale.\n\n\ud83c\udf3f Chaque d\u00e9tail est minutieusement r\u00e9alis\u00e9, des n\u00e9nuphars d\u00e9licats aux roseaux en p\u00e2te \u00e0 sucre, cr\u00e9ant une sc\u00e8ne sereine et charmante qui \u00e9voque une atmosph\u00e8re naturelle. Ce g\u00e2teau est une v\u00e9ritable invitation \u00e0 la douceur pour des moments inoubliables en famille et des photos qui resteront grav\u00e9es dans vos souvenirs." },
            { src: "8f9520f8-819f-4d0c-a0f2-353444913715_w.jpg", title: "Gucci 1", description: "\ud83c\udf81 Offrez une touche d'\u00e9l\u00e9gance avec ce g\u00e2teau inspir\u00e9 de la marque Gucci. Parfait pour les amateurs de mode, il combine style et gourmandise, id\u00e9al pour surprendre un proche lors d'un anniversaire ou d'une occasion sp\u00e9ciale.\n\n\u2728 Le design raffin\u00e9, avec ses motifs embl\u00e9matiques et ses couleurs sophistiqu\u00e9es, en fait un choix parfait pour ceux qui appr\u00e9cient le luxe et la distinction. Un g\u00e2teau qui allie parfaitement esth\u00e9tique et plaisir." },
            { src: "0cb63b24-973c-4483-8ef9-fdc6960acfab_w.jpg", title: "Harry Potter - Livre", description: "\ud83d\udcd6 Plongez dans la magie avec ce g\u00e2teau inspir\u00e9 des romans Harry Potter ! Avec sa forme de livre ancien, ses dorures \u00e9tincelantes et ses d\u00e9tails enchanteurs comme la baguette, le vif d\u2019or et les ailes dor\u00e9es, ce dessert semble tout droit sorti des couloirs de Poudlard. Un cadeau parfait pour tous les fans de la c\u00e9l\u00e8bre saga !\n\n\u2728 Chaque d\u00e9tail, du logo HP soigneusement sculpt\u00e9 aux accents dor\u00e9s, a \u00e9t\u00e9 con\u00e7u pour capturer l\u2019essence de cet univers fantastique. Ce g\u00e2teau est un v\u00e9ritable clin d\u2019\u0153il gourmand qui ravira petits et grands amoureux de magie et d\u2019aventures.\n\n\ud83c\udf89 Que ce soit pour un anniversaire ou pour surprendre un proche fan de sorcellerie, ce dessert est une d\u00e9licieuse fa\u00e7on de rendre le moment inoubliable. Pr\u00e9parez-vous \u00e0 des \"wow\" d\u00e8s le premier regard ! \ud83e\ude84" },
            { src: "98c2bb24-af05-4d78-9d37-db027f9518dc_w.jpg", title: "Harry Potter - Vif d'Or ", description: "\ud83c\udf82 Plongez dans un univers enchant\u00e9 avec ce g\u00e2teau \u00e0 la finition noire brillante. Chaque d\u00e9tail a \u00e9t\u00e9 pens\u00e9 pour \u00e9voquer un monde mystique et magique : du ballon ail\u00e9 soigneusement pos\u00e9 sur le dessus, \u00e0 la paire de lunettes embl\u00e9matique.\n\n\u26a1 Sur le devant, les initiales \"HP\" sont mises en valeur par des lettres dor\u00e9es, conf\u00e9rant une touche d'\u00e9l\u00e9gance \u00e0 l'ensemble. Pour personnaliser davantage cette cr\u00e9ation, le pr\u00e9nom de l'heureux ou l'heureuse f\u00eat\u00e9(e) est d\u00e9licatement inscrit sur le plateau.\n\n\ud83c\udf81 Id\u00e9al pour un amoureux des contes de sorcellerie, apportez une touche de magie avec ce g\u00e2teau qui saura charmer par sa finesse et son originalit\u00e9.\n\n" },
            { src: "f40c1748-2eb8-436c-97f1-9a0982fb4702_w.jpg", title: "Hockey - Canadiens Montr\u00e9al - Caufield", description: "\ud83c\udf82 Un g\u00e2teau qui fait du bruit chez les fans de hockey\u202f! D\u00e9couvrez notre cr\u00e9ation inspir\u00e9e du chandail num\u00e9ro 22 de Caufield, aux couleurs des Canadiens de Montr\u00e9al. Parfait pour surprendre un passionn\u00e9 et mettre un peu de saveur dans vos c\u00e9l\u00e9brations\u202f! \ud83c\udf89\n\n\ud83c\udfd2 Anniversaire, f\u00eate entre amis ou soir\u00e9e de match\u202f: ce g\u00e2teau est le clin d\u2019\u0153il gourmand parfait pour tous les fervents supporters. Les d\u00e9tails sont soigneusement r\u00e9alis\u00e9s pour rendre hommage \u00e0 votre joueur pr\u00e9f\u00e9r\u00e9 et \u00e0 son \u00e9quipe l\u00e9gendaire.\n" },
            { src: "c4b9171b-c37b-40fb-bd8e-dab5505284c1_w.jpg", title: "Hockey - Canadiens Montr\u00e9al - Price", description: "\ud83c\udfd2 Fan des Canadiens de Montr\u00e9al ou amoureux du hockey? D\u00e9couvrez ce g\u00e2teau unique qui reprend le c\u00e9l\u00e8bre chandail num\u00e9ro 31 de Price! \n\n\ud83c\udf82 Que ce soit pour une f\u00eate d'anniversaire, une soir\u00e9e entre amis ou simplement pour faire plaisir \u00e0 un fan inconditionnel, ce g\u00e2teau ajoutera une touche gourmande et originale \u00e0 votre c\u00e9l\u00e9bration. \ud83c\udf70\u2764\ufe0f\n\n\ud83c\udf89 Son design r\u00e9aliste et ses d\u00e9tails pr\u00e9cis en font un dessert qui fera sourire petits et grands. Offrez une surprise qui combine passion sportive et plaisir sucr\u00e9! \ud83c\udfd2\u2728" },
            { src: "43fac7b4-29f3-426f-b7e3-5a7bf5ac1213_w.jpg", title: "Infirmi\u00e8re Coeur", description: "\ud83c\udf70 C\u00e9l\u00e9brons les h\u00e9ros du quotidien avec ce g\u00e2teau d\u00e9di\u00e9 aux infirmi\u00e8res et infirmiers! Une d\u00e9licate repr\u00e9sentation de tout ce qu'ils apportent \u00e0 nos vies.\n\n\ud83e\ude7a Le c\u0153ur rose surmont\u00e9 d'un st\u00e9thoscope est plus qu'un simple d\u00e9tail, il \u00e9voque la passion et le d\u00e9vouement de ces professionnels. La ligne de rythme cardiaque est une ode \u00e0 la vie qu'ils prot\u00e8gent chaque jour.\n\n\ud83d\udc8a Entre pilules, pansements et comprim\u00e9s, chaque \u00e9l\u00e9ment du g\u00e2teau a \u00e9t\u00e9 pens\u00e9 pour refl\u00e9ter l'univers m\u00e9dical avec r\u00e9alisme et \u00e9l\u00e9gance. Un clin d'\u0153il \u00e0 leur quotidien.\n\n\ud83d\udc89 Et cette seringue orn\u00e9e du pr\u00e9nom? La touche finale pour une c\u00e9l\u00e9bration inoubliable. Car derri\u00e8re chaque geste technique se cache une attention personnalis\u00e9e.\n\nQue vous soyez dans le milieu m\u00e9dical ou que vous souhaitiez simplement rendre hommage \u00e0 ces h\u00e9ros, ce g\u00e2teau est fait pour vous. \ud83c\udf82\u2764\ufe0f" },
            { src: "30febab4-50ea-4bfa-8e8c-5c8f6312dae0_w.jpg", title: "Joyeux No\u00ebl 1", description: "Bonnes F\u00eates de fin d'ann\u00e9e  2024 !\n\n\ud83c\udf1f Notez que nous restons ouverts pendant toute la p\u00e9riode des f\u00eates. \ud83c\udf1f\n\n\ud83c\udf84 Ce magnifique g\u00e2teau de No\u00ebl aux couleurs festives rouge et verte illumine l\u2019esprit des f\u00eates. Ses d\u00e9tails soign\u00e9s, comme un sapin enneig\u00e9 en sucre et de jolis cadeaux scintillants, \u00e9voquent la magie des moments partag\u00e9s en famille. Une cr\u00e9ation gourmande et visuelle qui apporte une ambiance chaleureuse \u00e0 votre table.\n\n\ud83c\udf81 Avec son inscription \"Joyeux No\u00ebl\" dor\u00e9e et ses touches d\u00e9licates de paillettes, ce g\u00e2teau est bien plus qu\u2019un dessert : c\u2019est un geste attentionn\u00e9 pour surprendre vos proches, que ce soit pour une soir\u00e9e cosy ou un grand repas festif. Petits et grands seront s\u00e9duits par son design et ses saveurs gourmandes." },
            { src: "b94fd6b8-63dc-4ae5-8bb8-d883395140f4_w.jpg", title: "Lacoste Blanc et Or", description: "\ud83e\udde5 D\u00e9couvrez notre g\u00e2teau en forme de polo Lacoste, parfait pour surprendre les passionn\u00e9s de mode ! Chaque d\u00e9tail est minutieusement reproduit en respectant les codes de la marque, du col textur\u00e9 \u00e0 l'orientation des boutons dor\u00e9s en passant par le logo, le r\u00e9sultat donne un rendu authentique et sophistiqu\u00e9. \ud83c\udf82\u2728\n\n\ud83c\udf89 Id\u00e9al pour c\u00e9l\u00e9brer un anniversaire, ce g\u00e2teau peut \u00eatre personnalis\u00e9 avec un message sp\u00e9cial. Il ravira les fans de la marque en combinant \u00e9l\u00e9gance visuelle et d\u00e9lice gustatif pour un moment inoubliable. \ud83e\udd73\ud83c\udf70" },
            { src: "b9a7b586-eafe-4641-ba7d-89e24986c15a_w.jpg", title: "Lapin Dormeur", description: "\u2b50\ud83c\udf82\ud83c\udf80 Laissez-vous envo\u00fbter par ce g\u00e2teau \u00e0 deux \u00e9tages, soigneusement r\u00e9alis\u00e9 pour capturer toute la douceur et la po\u00e9sie d\u2019un moment pr\u00e9cieux. Sur le sommet, une adorable lapine en fondant repose paisiblement aux c\u00f4t\u00e9s d'une grande lune jaune, cr\u00e9ant une sc\u00e8ne pleine de tendresse et de r\u00eave. Les nuances pastel et les d\u00e9tails raffin\u00e9s, tels que les petites \u00e9toiles et les rubans d\u00e9licats, ajoutent une touche magique qui fera de ce g\u00e2teau la pi\u00e8ce ma\u00eetresse parfaite pour un anniversaire, une baby shower, ou toute autre c\u00e9l\u00e9bration sp\u00e9ciale. Ce dessert est con\u00e7u pour \u00e9merveiller et apporter une note d\u2019enchantement \u00e0 chaque instant partag\u00e9. \ud83c\udf19\ud83d\udc07" },
            { src: "28a1b447-3c1f-4903-acac-4e361b5ece59_w.jpg", title: "Licorne Arc-En-Ciel", description: "\ud83c\udf70 Vous r\u00eavez d'un g\u00e2teau aussi mignon qu'inoubliable? D\u00e9couvrez notre nouvelle cr\u00e9ation: un g\u00e2teau rose \u00e0 deux \u00e9tages qui respire la magie! \ud83c\udf08 Si les licornes et les arcs-en-ciel vous font sourire, ce g\u00e2teau est fait pour vous.\n\n\ud83c\udf08 Le premier \u00e9tage comporte un arc-en-ciel, des \u00e9toiles, des ballons et m\u00eame des petites t\u00eates de licorne! C'est comme si on avait mis un petit monde magique sur un g\u00e2teau.\n\n\ud83e\udd84 Et que dire de l'\u00e9tage sup\u00e9rieur? Deux licornes adorables roses et bleues, vous attendent sous un nuage de coton tout doux. On a m\u00eame ajout\u00e9 des ailes qui disent \"Happy Birthday\" pour couronner le tout!\n\n\ud83c\udf89 Pour la touche finale, des confettis multicolores donnent un air de f\u00eate \u00e0 l'ensemble. Parfait pour rendre heureux les petits et les grands qui ont gard\u00e9 leur \u00e2me d'enfant! \ud83e\udd73\n\n\ud83d\udc68\u200d\ud83d\udc69\u200d\ud83d\udc67\u200d\ud83d\udc66 Que vous souhaitiez surprendre un enfant ou \u00e9merveiller un adulte, ce g\u00e2teau saura charmer tout le monde. PS: Les d\u00e9corations sont pour les yeux, pas pour le ventre! \ud83d\ude09" },
            { src: "2324b7e4-233f-43d2-819b-33666664a4ae_w.jpg", title: "Licorne Chat", description: "\u2728\ud83e\udd84\ud83d\udc31 D\u00e9couvrez notre g\u00e2teau Licorne-Chat, une douce cr\u00e9ation pour les petites filles qui r\u00eavent de magie. Fusionnant l'\u00e9l\u00e9gance d'une licorne avec le charme d'un chat, ce g\u00e2teau est orn\u00e9 d'une corne dor\u00e9e, d'un visage de chat aux yeux ferm\u00e9s et d'une petite truffe rose. Les petites pattes de chat avec leurs coussinets roses et les oreilles pointues blanches et dor\u00e9es ajoutent une touche mignonne. La crini\u00e8re de la licorne en rosaces de cr\u00e9mage rose, jaune et blanc, avec quelques fleurs en fondant, compl\u00e8te cette pr\u00e9sentation soign\u00e9e et d\u00e9licate. Parfait pour un anniversaire ou une occasion sp\u00e9ciale ! \ud83c\udf82\ud83d\udc96" },
            { src: "31bb7d28-75d7-4b2e-ad46-5e303a766aec_w.jpg", title: "Licorne Mini", description: "\ud83e\udd84 D\u00e9couvrez notre adorable licorne d\u00e9bordante d'amour! C'est comme si elle avait saut\u00e9 hors d'un livre d'histoires pour enfants pour se poser tout en haut de notre g\u00e2teau aux couleurs pastel. Elle est tellement mignonne, vous aurez envie de lui faire des c\u00e2lins! \ud83e\udd17\n\n\ud83c\udf08 Et devinez quoi? Il y a un arc-en-ciel tout aussi chou qui suit notre licorne g\u00e9ante! Les petits nuages au pied sont tout sourire, comme s'ils \u00e9taient aussi heureux que vous de voir ce g\u00e2teau fantastique! \ud83c\udf25\ufe0f\n\n\u2728 Votre enfant sera aux anges! Son pr\u00e9nom est m\u00eame \u00e9crit devant le g\u00e2teau en lettres dor\u00e9es comme dans un vrai conte de f\u00e9es! \ud83c\udf1f\n\n\ud83c\udf89 Ce g\u00e2teau est comme un gros c\u00e2lin sucr\u00e9! Parfait pour les anniversaires, les f\u00eates f\u00e9eriques ou simplement pour rendre maman et papa super heureux! \ud83e\udd73\n\n\ud83d\ude0a On met tout notre amour et nos meilleures \u00e9nergies pour faire ce g\u00e2teau extra sp\u00e9cial, juste pour vous et vos petits bouts de chou. \u2764\ufe0f" },
            { src: "f3425ac3-13ec-4fa4-b985-f774c281babf_w.jpg", title: "Licorne Petit Coeur", description: "\ud83e\udd84\ud83c\udf82 Dites bonjour \u00e0 notre incroyable g\u00e2teau licorne! \ud83c\udf08 Ses petits yeux souriants ne demandent qu'\u00e0 \u00e9gayer votre journ\u00e9e. Il est tellement heureux de vous rencontrer!\n\n\u2728\ud83c\udf38 Ce g\u00e2teau a tout pour plaire! Une corne dor\u00e9e, des oreilles scintillantes et une crini\u00e8re en couleurs d'arc-en-ciel. Sans oublier les jolies petites fleurs en fondant! \ud83c\udf38\u2728\n\n\ud83d\udc96 Et ce n'est pas tout! On a ajout\u00e9 un c\u0153ur rouge tout doux sur sa joue, parce que l'amour, c'est important, non? \ud83d\udc96\n\n\ud83c\udf88\ud83d\udcdd Vous avez un message \u00e0 passer? Pas de probl\u00e8me! Votre pr\u00e9nom ou celui de quelqu'un que vous aimez sera ajout\u00e9 sur le plateau, pour un moment vraiment sp\u00e9cial! \ud83d\udcdd\ud83c\udf88\n\n\ud83c\udf89\ud83c\udf74 Fabriqu\u00e9 avec tout notre amour, ce g\u00e2teau est le r\u00eave de tous les enfants et m\u00eame des grands! Qui est pr\u00eat pour un moment sucr\u00e9 et f\u00e9\u00e9rique? \ud83c\udf74\ud83c\udf89" },
            { src: "5aec476a-08a6-4ed1-8ad6-e53e2576ac37_w.jpg", title: "Licorne Zen", description: "\ud83c\udf82 D\u00e9couvrez la s\u00e9r\u00e9nit\u00e9 sucr\u00e9e avec notre G\u00e2teau Licorne Zen! \ud83e\udd84 Parfait pour les adeptes de l'imaginaire ou les parents souhaitant \u00e9merveiller leur petit tr\u00e9sor.\n\n\ud83c\udf6b Personnalisez-le avec des su\u00e7ons en chocolat blanc portant le pr\u00e9nom du prince ou de la prncesse de la f\u00eate! \ud83d\udc51\n\n\u2709\ufe0f Nous rendons chaque g\u00e2teau unique en ajoutant votre message personnel sur une plaque de fondant. \ud83d\udc8c\n\n\ud83c\udf1f Et pour finir, le g\u00e2teau est saupoudr\u00e9 de perles de sucre qui ajoutent une touche scintillante qui ne manquera pas de plaire aux enfants." },
            { src: "d3bbf234-831e-47c8-a25e-e4ade4dea482_w.jpg", title: "Licorne Zen 2", description: "\ud83c\udf08 D\u00e9couvrez notre Licorne Zen en version 3 \u00e9tages, un g\u00e2teau qui saura captiver tant les petits que les grands. C'est le choix parfait pour ajouter une touche d'\u00e9merveillement \u00e0 votre c\u00e9l\u00e9bration. \ud83c\udf82\n\n\ud83c\udf38 La base du g\u00e2teau, agr\u00e9ment\u00e9e de fleurs en fondant faites \u00e0 la main, ajoute une nuance de raffinement et d'\u00e9l\u00e9gance. \ud83c\udf3a\n\n\ud83e\udd84 En vedette, le profil serein et apaisant d'une licorne en fondant. Son expression calme \u00e9voque une sorte de magie tranquille, parfaite pour capturer l'imaginaire des enfants. \u2728\n\n\ud83c\udf08 Et pour personnaliser le tout, un arc-en-ciel en fondant avec le pr\u00e9nom de la personne f\u00eat\u00e9e.\n" },
            { src: "c6479848-b15c-4bfb-82cb-7e0b006ec7b4_w.jpg", title: "Lion 1", description: "\ud83e\udd81 Rencontrez le roi mignon de la f\u00eate ! Notre topper lion en fondant est pr\u00eat \u00e0 tr\u00f4ner sur votre g\u00e2teau avec sa couronne miniature et son sourire craquant. Parfait pour les f\u00eates d'enfants ou pour apporter une touche de joie \u00e0 n'importe quelle c\u00e9l\u00e9bration. Laissez-le rugir de douceur sur votre dessert ! \ud83d\ude0d\ud83c\udf89" },
            { src: "59b24111-26ae-4b91-a21f-26ab89f9387b_w.jpg", title: "Mariage Initiales et Fleurs", description: "\ud83c\udf70 Vous \u00eates \u00e0 la recherche d'un g\u00e2teau de mariage? D\u00e9couvrez notre g\u00e2teau \u00e0 deux \u00e9tages, orn\u00e9 de d\u00e9tails en dentelle et de fleurs en fondant. Une beaut\u00e9 sobre et \u00e9l\u00e9gante qui s'adapte \u00e0 tous les types de mariages! \ud83d\udc92\n\n\ud83d\udc96 Vous aimez les petits d\u00e9tails? Nous aussi! \ud83c\udf38 Ce g\u00e2teau arbore des fleurs artisanales en fondant aux tons doux de rose et blanc, avec un pistil dor\u00e9 qui ajoute juste ce qu'il faut d'\u00e9clat. \u2728\n\n\ud83d\udd20 Personnalisez-le \u00e0 votre mani\u00e8re! Avec deux \u00e9tages de 10 et 8 pouces, vous avez tout l'espace n\u00e9cessaire pour ajouter une touche personnelle. \ud83d\udccf Que ce soit vos noms, la date du grand jour, ou une citation sp\u00e9ciale, on s'occupe de tout! \ud83e\udd0d\n\n\ud83e\udd73 Que vous soyez le couple vedette du jour ou que vous cherchiez \u00e0 offrir un cadeau de mariage extraordinaire, ce g\u00e2teau est fait pour vous! \ud83c\udfa9\ud83d\udc70 Plus qu'un dessert, c'est un \u00e9l\u00e9ment phare de votre grande journ\u00e9e. \ud83c\udf1f\n" },
            { src: "4a934fbd-8789-418a-8c81-6d5242e90de4_w.jpg", title: "Mariage Perles et Fleurs", description: "\ud83c\udf70 D\u00e9couvrez notre g\u00e2teau de mariage \u00e0 trois niveaux, une pi\u00e8ce ma\u00eetresse qui allie \u00e9l\u00e9gance et simplicit\u00e9. Ce g\u00e2teau est habill\u00e9 d'une multitude de perles de sucre offrant une touche de raffinement tout en subtilit\u00e9 et symbolisant une \u00e9l\u00e9gance intemporelle.\n\n\ud83c\udf38 Le romantisme est \u00e9galement \u00e0 l'honneur avec une parure de fleurs artificielles s\u00e9lectionn\u00e9es avec soin . Les plumes de pampa rose et les brins de gypsophile ajoutent une dimension douce et l\u00e9g\u00e8re, invitant \u00e0 la tendresse.\n\n\ud83c\udf81 Ce g\u00e2teau est l'expression parfaite d'un amour pur et d'un engagement solennel. Il saura toucher le c\u0153ur des amoureux, comme celui des proches d\u00e9sireux d'offrir un cadeau de mariage empreint de beaut\u00e9 et de sensibilit\u00e9.\n\nNous mettons un point d'honneur \u00e0 ce que chaque g\u00e2teau soit aussi unique que votre histoire d'amour. Pour toute commande ou personnalisation, n'h\u00e9sitez pas \u00e0 nous contacter. Votre bonheur est notre plus belle r\u00e9compense." },
            { src: "02cca4e7-207c-425b-afd6-42588209fefa_w.jpg", title: "Mariage YW", description: "Nous sommes fiers de vous pr\u00e9senter notre premier g\u00e2teau de mariage pour 150 personnes, 1h de montage sur place, 20kg !\n\nF\u00e9cilications aux heureux mari\u00e9s et plein de bonheur ! \ud83d\udc70\ud83c\udfff\ud83e\udd35\ud83c\udfff" },
            { src: "a37fc692-d9b6-48a5-8e34-90b3937a4b21_w.jpg", title: "Meilleurs Amis", description: "\ud83d\udc67\ud83d\udc96\ud83d\udc15 D\u00e9couvrez cette douce cr\u00e9ation personnalis\u00e9e c\u00e9l\u00e9brant l\u2019amiti\u00e9 entre une petite fille et son fid\u00e8le compagnon. Les figurines en fondant, fa\u00e7onn\u00e9es avec soin, racontent l\u2019histoire d\u2019Isabelle et de son Boubou, ins\u00e9parables. Un arc-en-ciel aux teintes pastel et des petites fleurs color\u00e9es apportent une atmosph\u00e8re joyeuse et r\u00eaveuse. \ud83e\udd8b\n\n\ud83c\udfa8 Vous avez une id\u00e9e en t\u00eate ? Confiez-nous vos envies ! Nous serons ravis de cr\u00e9er un g\u00e2teau qui raconte *votre* histoire, avec la m\u00eame attention aux d\u00e9tails et le m\u00eame amour que dans cette r\u00e9alisation." },
            { src: "3be15236-5244-49ae-8636-84840c408f80_w.jpg", title: "Minecraft 1", description: "\ud83c\udfae Plongez dans l'univers de Minecraft avec ce g\u00e2teau con\u00e7u sp\u00e9cialement pour mettre en valeur des figurines Minecraft \u00e0 offrir pour les fans du jeu vid\u00e9o.\n\n\ud83d\udc7e Les textures et couleurs sont inspir\u00e9es directement du monde pixelis\u00e9 de Minecraft avec des \u00e9l\u00e9ments comestibles soigneusement r\u00e9alis\u00e9s pour capturer l'essence du jeu comme les blocs de TNT et l'\u00e9p\u00e9e en diamand. Vous n'avez plus qu'\u00e0 rajouter vos figurines sur le g\u00e2teau!" },
            { src: "9ceca2c3-eba4-4dd4-9af5-f89e9883e9fb_w.jpg", title: "Minion 1", description: "\ud83c\udf4c Offrez \u00e0 vos petits fans de Minions une surprise m\u00e9morable avec ce g\u00e2teau d'anniversaire inspir\u00e9 de Bob ! Avec sa couleur jaune \u00e9clatante et son sourire malicieux, ce g\u00e2teau original apportera une bonne dose de fun \u00e0 votre \u00e9v\u00e9nement, captant l'attention de tous, petits et grands !\n\n\ud83e\uddc1 R\u00e9alis\u00e9 avec minutie, chaque d\u00e9tail de ce g\u00e2teau est pens\u00e9 pour ravir les fans : les grands yeux expressifs de Bob, sa salopette bleue. Tout est fait \u00e0 la main pour donner vie \u00e0 ce personnage ador\u00e9 et offrir un v\u00e9ritable plaisir visuel et gustatif. Et on a m\u00eame pens\u00e9 aux bananaaaaaas ! " },
            { src: "8ceeaf10-55e8-44c6-8b33-69146ad85889_w.jpg", title: "Nuage Fruit\u00e9 Mascarpone", description: "Nouvelle recette! \ud83c\udf89 D\u00e9couvrez notre Nuage Fruit\u00e9 Mascarpone. C'est la version cheesecake de notre g\u00e2teau signature \"Nuage Fruit\u00e9\", encore plus cr\u00e9meux et avec un look totalement revisit\u00e9. \ud83c\udf38 La d\u00e9coration en rosettes de cr\u00e8me au beurre rose, parsem\u00e9e de petites perles d\u00e9licates, donne un effet de fleur \u00e9panouie qui attirera tous les regards. \ud83d\ude0d Les copeaux de chocolat blanc qui enrobent le g\u00e2teau ajoutent une touche croquante \u00e0 cette douceur fondante. Une gourmandise \u00e9l\u00e9gante qui fond en bouche et fera de chaque occasion un moment unique \u00e0 savourer. \u2728\n\nhttps://jomiepatisserie.com/products/nuage-fruite-mascarpone" },
            { src: "1d12a9aa-14ae-4f59-b370-c3f5203b980e_w.jpg", title: "Oh Baby Classique", description: "\ud83c\udf70 D\u00e9couvrez notre g\u00e2teau \"Oh Baby\" ! Avec son fondant \u00e0 effet pliss\u00e9, il incarne \u00e0 la perfection simplicit\u00e9 et \u00e9l\u00e9gance pour tous les moments sp\u00e9ciaux.\n\n\ud83c\udf3f Sa d\u00e9coration raffin\u00e9e de brins verdoyants offre une touche naturelle, combinant \u00e9clat et charme champ\u00eatre.\n\n\ud83d\udc96 Le topper \"Oh Baby\" surmont\u00e9 de c\u0153urs dor\u00e9s en fait le choix id\u00e9al pour les baby showers ou les reveal gender. Faites vivre \u00e0 vos proches des moments m\u00e9morables !" },
            { src: "2cccf497-acc9-4eba-ab60-1f527aea954a_w.jpg", title: "Petit B\u00e2teau", description: "\ud83c\udf82 Parfait pour votre petit aventurier ou petite aventuri\u00e8re maritime, notre g\u00e2teau smash cake de 5 pouces va faire des vagues \u00e0 votre c\u00e9l\u00e9bration! Sa finition en fondant bleu fait voyager dans l'univers de la mer. \ud83c\udf0a\n\n\u2693 Avec des d\u00e9corations soign\u00e9es comme des bou\u00e9es, une ancre et une corde de bateau, ce g\u00e2teau \u00e9veillera l'imaginaire des tout-petits tout en \u00e9blouissant les plus grands.\n\n\ud83d\udea2 Un adorable petit bateau en fondant et riz souffl\u00e9 orne le sommet, naviguant sur un oc\u00e9an de douces vagues bleues et blanches. Une v\u00e9ritable aventure en sucre pour B\u00e9b\u00e9! \ud83e\uddd2\n\n\ud83c\udf89 Votre petit marin est \u00e0 l'honneur! Son \u00e2ge figure sur la voile et son pr\u00e9nom trouve sa place \u00e0 l'avant du g\u00e2teau, pour une touche vraiment personnalis\u00e9e. \ud83c\udf88\n\n\ud83d\udcf8 Le g\u00e2teau est aussi photog\u00e9nique que d\u00e9licieux, id\u00e9al pour des moments inoubliables en studio ou en famille. \ud83d\udcf7\n" },
            { src: "146a08c8-0fa0-4a0b-9344-b93d7e742558_w.jpg", title: "Petite Sir\u00e8ne 1", description: "\ud83e\udddc\u200d\u2640\ufe0f Plongez dans l'univers enchant\u00e9 de nos derniers g\u00e2teaux sur le th\u00e8me de la c\u00e9l\u00e8bre petite sir\u00e8ne ! Avec leurs couleurs douces et leurs magnifiques d\u00e9tails, ces cr\u00e9ations invitent \u00e0 d\u00e9couvrir un monde sous-marin magique qui ravira petits et grands. Une touche de f\u00e9\u00e9rie \u00e0 ajouter \u00e0 vos c\u00e9l\u00e9brations !\n\n\ud83d\udc20 Coquillages d\u00e9licats, queue de sir\u00e8ne \u00e9l\u00e9gante et perles scintillantes cr\u00e9ent une composition raffin\u00e9e qui fait \u00e9cho aux l\u00e9gendes de l'oc\u00e9an. Une merveille pour tous ceux qui sont fascin\u00e9s par les histoires de sir\u00e8nes et les mondes fantastiques.\n\n\ud83c\udf89 Parfait pour rendre une f\u00eate inoubliable, ces g\u00e2teaux captiveront vos invit\u00e9s et comblera les gourmands avec leur d\u00e9coration soign\u00e9e et son univers fascinant. Une belle surprise pour faire briller les yeux des enfants comme des adultes !" },
            { src: "84a77b9e-bd86-4ba2-8210-6e26dba6d8c9_w.jpg", title: "Petite Sir\u00e8ne 2", description: "\ud83e\udddc\u200d\u2640\ufe0f Plongez dans l'univers enchant\u00e9 de nos derniers g\u00e2teaux sur le th\u00e8me de la c\u00e9l\u00e8bre petite sir\u00e8ne ! Avec leurs couleurs douces et leurs magnifiques d\u00e9tails, ces cr\u00e9ations invitent \u00e0 d\u00e9couvrir un monde sous-marin magique qui ravira petits et grands. Une touche de f\u00e9\u00e9rie \u00e0 ajouter \u00e0 vos c\u00e9l\u00e9brations !\n\n\ud83d\udc20 Coquillages d\u00e9licats, queue de sir\u00e8ne \u00e9l\u00e9gante et perles scintillantes cr\u00e9ent une composition raffin\u00e9e qui fait \u00e9cho aux l\u00e9gendes de l'oc\u00e9an. Une merveille pour tous ceux qui sont fascin\u00e9s par les histoires de sir\u00e8nes et les mondes fantastiques.\n\n\ud83c\udf89 Parfait pour rendre une f\u00eate inoubliable, ces g\u00e2teaux captiveront vos invit\u00e9s et comblera les gourmands avec leur d\u00e9coration soign\u00e9e et son univers fascinant. Une belle surprise pour faire briller les yeux des enfants comme des adultes !" },
            { src: "70e5ad5c-38ad-4e84-9627-9a2ea268572f_w.jpg", title: "Plage et \u00c9toile de Mer", description: "Plongez dans la s\u00e9r\u00e9nit\u00e9 d'un paradis tropical avec notre g\u00e2teau plage artistique! \ud83c\udf05 Chaque d\u00e9tail \u00e9voque une \u00e9chapp\u00e9e belle sur une plage paisible, du tableau de plage comestible ornant le devant, aux palmiers qui se balancent doucement au-dessus des chaises longues. \ud83c\udf34\n\nD\u00e9couvrez un sommet orn\u00e9 d'une \u00e9toile de mer imposante et de coquillages d\u00e9licats, une cr\u00e9ation qui \u00e9veille les sens et invite \u00e0 un voyage gustatif au c\u0153ur de l'oc\u00e9an. \ud83d\udc1a Admirez les palmiers en miniature ajoutant une profondeur captivante, avec un palmier en 3D pour une exp\u00e9rience immersive. \ud83c\udf33\n\nLa base du g\u00e2teau, saupoudr\u00e9e de miettes de biscuits rappelant le sable chaud, est parsem\u00e9e de petites d\u00e9corations de coquillages et de perles. \ud83c\udfd6\ufe0f\n\nC\u00e9l\u00e9brez avec nous le calme des plages tropicales et laissez ce g\u00e2teau vous emporter loin du quotidien. Partagez un moment de pure douceur avec cette gourmandise qui transformera votre \u00e9v\u00e9nement en un souvenir inoubliable.\n\n" },
            { src: "0a7f66e8-a64e-4e4c-8cd9-b990b38dc14e_w.jpg", title: "Pokemon Evee", description: "\ud83c\udf88 Ce g\u00e2teau tout doux \u00e0 l'effigie d'\u00c9voli (Eevee) est pr\u00eat \u00e0 rejoindre la f\u00eate d'anniversaire de votre enfant ! Avec son pelage tout mignon et ses grandes oreilles, il attend impatiemment de partager ce moment avec ses amis. \ud83c\udf89 Savez-vous qu'\u00c9voli a la capacit\u00e9 de se transformer en huit Pok\u00e9mon diff\u00e9rents ? Un vrai champion des surprises !\n\n\ud83c\udf82 Entour\u00e9 de petites fleurs et de deux Pok\u00e9balls pr\u00eates \u00e0 capturer la magie, ce g\u00e2teau chocolat\u00e9 fera briller les yeux de tous les petits dresseurs de Pok\u00e9mon qui l'accompagneront." },
            { src: "56df21d2-dca9-478d-82ed-ce1edc080370_w.jpg", title: "Pot de fleurs", description: "\ud83c\udf38 Offrez un moment d'\u00e9merveillement avec ce g\u00e2teau qui fleurit autant les c\u0153urs que les tables. Un pot d\u00e9licatement fa\u00e7onn\u00e9, enti\u00e8rement comestible, o\u00f9 chaque d\u00e9tail compte. La g\u00e9noise au chocolat rappelle la terre fertile, tandis que de jolies fleurs artificielles apportent une touche color\u00e9e et \u00e9l\u00e9gante. \n\n\ud83c\udf37 Pour les mains vertes, nous avons pens\u00e9 \u00e0 ajouter une pelle en fondant, personnalis\u00e9e avec le pr\u00e9nom et l'\u00e2ge de la personne c\u00e9l\u00e9br\u00e9e ! \ud83d\udc69\u200d\ud83c\udf3e" },
            { src: "9c112cbc-e4ba-4931-be49-d3163168df1b_w.jpg", title: "Reine des Neiges 1", description: "\ud83c\udf1f Entrez dans l'univers magique de la Reine des Neiges avec nos g\u00e2teaux originaux, con\u00e7us avec amour et cr\u00e9ativit\u00e9 ! Remplis de neige, de glace scintillante et des personnages pr\u00e9f\u00e9r\u00e9s des petits, chaque d\u00e9tail est pens\u00e9 pour faire briller les yeux des enfants et ajouter une touche f\u00e9erique \u00e0 leur journ\u00e9e sp\u00e9ciale. \u2744\ufe0f\n\n\u26c4 Ces g\u00e2teaux, c'est la magie de la Reine des Neiges en version sucr\u00e9e ! Avec leurs flocons et leurs couleurs glac\u00e9es, ils apportent un vent de fra\u00eecheur et de f\u00e9erie \u00e0 chaque anniversaire. Les petits fans seront ravis de retrouver leurs personnages pr\u00e9f\u00e9r\u00e9s sur un g\u00e2teau aussi sp\u00e9cial ! \u2728" },
            { src: "de85df6d-1ef2-4f8a-9eba-beacb85e67cf_w.jpg", title: "Reine des Neiges 2", description: "\u2744\ufe0f Faites vivre un moment f\u00e9\u00e9rique avec ce g\u00e2teau inspir\u00e9 de l'univers magique de La Reine des Neiges ! Parfait pour surprendre les jeunes fans d'Elsa et Olaf, ce g\u00e2teau se d\u00e9cline en nuances de bleu et d\u00e9tails givr\u00e9s, recr\u00e9ant l'ambiance glac\u00e9e du film pour \u00e9merveiller petits et grands.\n\n\ud83c\udf82 D\u00e9cor\u00e9 avec soin, chaque flocon de neige et perle comestible \u00e9voque la beaut\u00e9 de l'hiver, tandis que l'image d'Elsa en pleine magie ajoute une touche de fantaisie qui fera r\u00eaver les enfants. Ce g\u00e2teau est un souvenir d\u00e9licieux et m\u00e9morable \u00e0 partager en famille ou entre amis !" },
            { src: "cd181539-44a7-4667-a918-1fde6a7c360f_w.jpg", title: "Reine des Neiges 3", description: "\ud83c\udf82 Plongez dans l'univers f\u00e9\u00e9rique de la Reine des Neiges avec ce magnifique g\u00e2teau inspir\u00e9 des personnages bien-aim\u00e9s d'Elsa, Anna et Olaf ! Les nuances pastel et les flocons de neige d\u00e9licatement pos\u00e9s ajoutent une touche de magie hivernale, parfaite pour \u00e9merveiller petits et grands. Ce g\u00e2teau transportera votre c\u00e9l\u00e9bration au c\u0153ur d'Arendelle, l\u00e0 o\u00f9 tout est possible !\n\n\u2744\ufe0f Avec Elsa et Anna en premi\u00e8re ligne, accompagn\u00e9es du joyeux Olaf, ce g\u00e2teau apporte une dose de tendresse et de joie \u00e0 chaque tranche. Une cr\u00e9ation id\u00e9ale pour rendre un anniversaire inoubliable ou pour offrir une belle surprise \u00e0 un passionn\u00e9 de cet univers enchanteur.\n\n\ud83c\udf88 Ce g\u00e2teau d\u00e9licatement travaill\u00e9 et orn\u00e9 de d\u00e9tails raffin\u00e9s est con\u00e7u pour captiver les invit\u00e9s et cr\u00e9er des souvenirs m\u00e9morables. Une touche de douceur pour chaque moment en famille, o\u00f9 chaque bouch\u00e9e est aussi belle que d\u00e9licieuse !" },
            { src: "fa3e1d2b-199d-4207-b77d-d3fb678385a5_w.jpg", title: "Reine des Neiges 4", description: "\ud83e\uddc1 Plongez dans la f\u00e9\u00e9rie de la Reine des Neiges avec ce g\u00e2teau enchanteur ! Avec ses nuances douces de rose et de bleu, ce dessert \u00e9merveille par son d\u00e9grad\u00e9 d\u00e9licat et ses d\u00e9corations raffin\u00e9es. Un v\u00e9ritable plaisir pour les yeux et les papilles qui ravira petits et grands gourmands.\n\n\ud83c\udf82 Anna, Elsa et le joyeux Olaf sont l\u00e0 pour rendre ce g\u00e2teau encore plus magique ! Leur pr\u00e9sence apporte une touche ludique et color\u00e9e qui captera instantan\u00e9ment l\u2019attention des petits fans. Les flocons de neige comestibles ajoutent une touche de magie nordique, rappelant l\u2019univers glac\u00e9 du film.\n\n\ud83c\udf88 Parfait pour c\u00e9l\u00e9brer un anniversaire m\u00e9morable, ce g\u00e2teau a \u00e9t\u00e9 con\u00e7u pour les enfants fascin\u00e9s par l\u2019univers magique de la Reine des Neiges. Chaque d\u00e9tail, soigneusement r\u00e9alis\u00e9, transforme ce dessert en un moment unique et inoubliable." },
            { src: "08397873-4bb9-473a-9e79-be2fe89ef2bc_w.jpg", title: "Reine des Neiges 5", description: "\u2744\ufe0f Offrez un moment magique avec notre g\u00e2teau inspir\u00e9 de la Reine des Neiges, parfait pour \u00e9merveiller les petits fans d\u2019Elsa, Anna et Olaf ! Con\u00e7u pour capturer l\u2019univers f\u00e9\u00e9rique d\u2019Arendelle, ce g\u00e2teau pr\u00e9sente des personnages embl\u00e9matiques mis en valeur dans un d\u00e9cor pastel et givr\u00e9, rappelant les paysages enneig\u00e9s du royaume. \u2744\ufe0f\u2728\n\n\ud83d\udc67 Ce g\u00e2teau est id\u00e9al pour un anniversaire ou une occasion sp\u00e9ciale, destin\u00e9 aux enfants qui r\u00eavent d'une f\u00eate aux couleurs de leurs h\u00e9ro\u00efnes pr\u00e9f\u00e9r\u00e9es. Avec ses d\u00e9tails en sucre, dont Olaf et des flocons de neige d\u00e9licatement sculpt\u00e9s, il ajoute une touche de fantaisie et de fra\u00eecheur \u00e0 chaque c\u00e9l\u00e9bration. \ud83e\uddc1\u2744\ufe0f\n\n\ud83c\udf82 La base aux teintes pastel et \u00e0 la finition lisse est agr\u00e9ment\u00e9e de d\u00e9corations glac\u00e9es, apportant une \u00e9l\u00e9gance sobre et soign\u00e9e qui charmera \u00e0 la fois les yeux et les papilles. Ce dessert est une v\u00e9ritable invitation \u00e0 plonger dans la f\u00e9erie de la Reine des Neiges ! \u2744\ufe0f\ud83d\udc78\ud83c\udffb" },
            { src: "be60e80f-d13d-41e3-8a26-7e767f0adfdb_w.jpg", title: "Reine des Neiges 6", description: "\u2744\ufe0f Laissez-vous transporter dans un univers givr\u00e9 avec notre g\u00e2teau inspir\u00e9 de la Reine des Neiges. Son gla\u00e7age aux nuances de bleu, ses d\u00e9corations enneig\u00e9es et ses figurines rendent hommage \u00e0 la magie du c\u00e9l\u00e8bre royaume glac\u00e9. Un moment doux et enchanteur vous attend\u202f!\n\n\u200d\u200d\u200d\u200d\ud83d\udc68\u200d\ud83d\udc69\u200d\ud83d\udc67\u200d\ud83d\udc66 Ce dessert r\u00e9unit parents et enfants autour d\u2019un instant de complicit\u00e9 et de gourmandise. Il fera sourire les fans inconditionnels du film, tout en ajoutant une note de fantaisie aux c\u00e9l\u00e9brations familiales et aux occasions sp\u00e9ciales.\n\n\u2728 Personnalisable selon vos envies, ce g\u00e2teau s\u2019adapte \u00e0 toutes les envies festives. Son apparence soign\u00e9e et son go\u00fbt d\u00e9licat invitent \u00e0 savourer un v\u00e9ritable moment de plaisir." },
            { src: "25677603-c223-4a37-bf00-0f0c0c443168_w.jpg", title: "Renard Simple", description: "\ud83e\udd8a C\u00e9l\u00e9brez un moment important avec notre g\u00e2teau renard, id\u00e9al pour marquer une ann\u00e9e pleine d'\u00e9merveillement de votre petit bout de chou! Avec ses traits simples et son design amusant, ce g\u00e2teau repr\u00e9sente une mignonne t\u00eate de renard, avec de grandes oreilles attentives et une queue touffue qui s'enroule tout en douceur autour de sa base.\n\n\ud83c\udf82 Sur le sommet un grand chiffre \"1\" qui attire l'attention. Et cette frimousse de renard avec ses petits yeux et sa petite truffe ne demande qu'\u00e0 \u00eatre d\u00e9vor\u00e9e par les regards avant de l'\u00eatre par les papilles!\n\n\ud83c\udf89 Chaque photo de ce g\u00e2teau sera une invitation \u00e0 partager la joie et la douceur de l'enfance. Pr\u00e9parez-vous \u00e0 recevoir une pluie de \"J'adore\" et \"Trop mignon\" en partageant ce moment unique." },
            { src: "bd1e9fd3-1aa5-4c5b-a81d-cc9c2430d162_w.jpg", title: "Robe de Poup\u00e9e", description: "\ud83c\udf80 Nous transformons votre poup\u00e9e Barbie en une princesse gourmande avec une robe en g\u00e2teau ! Offrez une surprise unique et inoubliable \u00e0 votre enfant avec ce g\u00e2teau qui marie \u00e9l\u00e9gance et magie. Chaque robe est adapt\u00e9e au style de la poup\u00e9e pour un effet naturel et raffin\u00e9. \ud83d\udcab\n\n\ud83d\udc57 La robe de ce g\u00e2teau, parfaitement sculpt\u00e9e, combine des nuances de lavande et de rose, cr\u00e9ant une harmonie d\u00e9licate et apaisante. Chaque d\u00e9tail, des plis fluides aux roses subtiles, est travaill\u00e9 avec pr\u00e9cision pour une finition impeccable. Le pr\u00e9nom de votre enfant en lettres dor\u00e9es ajoute cette touche personnelle qui rend le moment encore plus m\u00e9morable.\n\n\ud83d\udcf8 Partagez ce moment magique avec vos proches et laissez-les d\u00e9couvrir ce chef-d'\u0153uvre p\u00e2tissier." },
            { src: "201369cf-fc85-4c60-b99b-3c49ccf520a5_w.jpg", title: "Rose Couture ", description: "Plongez dans notre derni\u00e8re merveille sucr\u00e9e d\u00e9di\u00e9e aux amoureux de la couture! \ud83e\udea1\ud83d\udc9d\n\n\ud83e\uddf5 Chaque \u00e9l\u00e9ment de ce g\u00e2teau, du fondant blanc \u00e9clatant aux d\u00e9licats boutons roses, est une v\u00e9ritable ode \u00e0 l'univers du fil et de l'aiguille. Laissez-vous s\u00e9duire par ce mannequin \u00e9l\u00e9gamment drap\u00e9, majestueusement entour\u00e9 de bobines, de boutons et d'un authentique ruban \u00e0 mesurer. Chaque d\u00e9tail est pens\u00e9 et r\u00e9alis\u00e9 avec amour, refl\u00e9tant la passion et le d\u00e9vouement \u00e0 l'artisanat.\n\n\ud83c\udf80 Que ce soit pour un anniversaire, une c\u00e9l\u00e9bration sp\u00e9ciale ou simplement pour surprendre un proche passionn\u00e9, cette cr\u00e9ation est un v\u00e9ritable voyage au c\u0153ur de la couture et de la gourmandise. \ud83c\udf89" },
            { src: "5d6ceac0-4c46-4e3f-b36f-d0ac7ec4076f_w.jpg", title: "Sac \u00e0 Main 2", description: "Craquez pour notre g\u00e2teau Sac \u00e0 Main, le must-have des c\u00e9l\u00e9brations styl\u00e9es ! \ud83c\udf82\n\n\u00c9l\u00e9gance et gourmandise se rencontrent dans cette cr\u00e9ation p\u00e2tissi\u00e8re inspir\u00e9e des tendances de la haute couture. Id\u00e9al pour impressionner une amie fashionista ou g\u00e2ter un \u00eatre cher avec un clin d'\u0153il au luxe. \ud83c\udf81\n\nFinitions d\u00e9licates, motifs iconiques et une fleur d\u00e9licatement pos\u00e9e, ce g\u00e2teau est une pi\u00e8ce d'exception pour vos moments pr\u00e9cieux. \ud83d\udc90\n\nGo\u00fbtez \u00e0 l'exclusivit\u00e9 avec des ingr\u00e9dients de choix. Pour une exp\u00e9rience aussi belle que d\u00e9licieuse, optez pour notre g\u00e2teau Sac \u00e0 Main. \ud83c\udf70" },
            { src: "c28af359-65f7-444a-a636-434c0eef6836_w.jpg", title: "Smash Cake Alessia", description: "\ud83c\udf70 Immortalisez les premiers \u00e9clats de rire de votre b\u00e9b\u00e9 avec ce g\u00e2teau Smash Cake \u00e9l\u00e9gamment drap\u00e9 de rose. Son doux nuancier ajoutera une note f\u00e9erique \u00e0 vos photos.\n\n\ud83d\udc76 Un festival de textures : L'arc-en-ciel au sommet du g\u00e2teau pr\u00e9sente une vari\u00e9t\u00e9 de textures soigneusement \u00e9labor\u00e9es, offrant un plaisir visuel et tactile. Les petites fleurs et les boules en fondant, quant \u00e0 elles, se d\u00e9clinent en diff\u00e9rentes nuances de rose. La guirlande suspendue, avec ses petites franges roses, apporte une autre dimension textur\u00e9e au g\u00e2teau. La base est soigneusement bord\u00e9e d'une autre ceinture textur\u00e9e rose, ajoutant une touche finale \u00e9l\u00e9gante \u00e0 ce g\u00e2teau qui offre \u00e0 b\u00e9b\u00e9 une exp\u00e9rience multisensorielle.\n\n\ud83c\udf80 Et pour rendre ce moment encore plus sp\u00e9cial, le pr\u00e9nom de votre ange est grav\u00e9 en douces lettres blanches. Parfait pour une s\u00e9ance photo m\u00e9morable !" },
            { src: "9036d21c-a7c7-4ffe-9fda-56da9eec6a7b_w.jpg", title: "Smash Cupcake", description: "\ud83e\uddc1 Savourez l'instant magique du premier anniversaire de votre bout de chou avec notre g\u00e2teau con\u00e7u tel un cupcake g\u00e9ant avec ses grandes rosaces en cr\u00e8me au beurre invitant \u00e0 la tendresse et \u00e0 la c\u00e9l\u00e9bration. \n\n\ud83e\udd47Ce g\u00e2teau se distingue par un imposant chiffre \"1\" recouvert de poudre d'or comestible, apportant une touche d'\u00e9l\u00e9gance \u00e0 cet \u00e9v\u00e9nement joyeux. Orn\u00e9 de papillons fins et de perles de fondant, ses d\u00e9corations sont une promesse de magie et de douceur pour ce moment inoubliable." },
            { src: "ba385f12-4706-4b68-a0b1-836324f0b593_w.jpg", title: "Sous l'eau", description: "\ud83d\udc0b Plongez dans un monde marin joyeux et color\u00e9 avec ce g\u00e2teau d\u2019anniversaire plein de charme! Sur le sommet, un petit plongeur ou une petite plongeuse prennent place pr\u00eats \u00e0 embarquer pour une aventure sous-marine \u00e0 dos de baleine!\n\n\ud83d\udc22 Cette aventure est l'occasion de rencontrer une tortue curieuse, une baleine tranquille ou encore quelques coquillages et coraux d\u00e9licats.\n\n\ud83e\udd3f Le personnage peut \u00eatre personnalis\u00e9 pour apporter une touche unique \u00e0 cette cr\u00e9ation. Le pr\u00e9nom est d\u00e9licatement inscrit en fondant sur le devant du g\u00e2teau." },
            { src: "72c35caa-fb3d-41ff-ab59-b91c120e1567_w.jpg", title: "Spiderman 1", description: "\ud83c\udf82 Pour les petits super-h\u00e9ros en devenir, notre g\u00e2teau sur le th\u00e8me de Spiderman. Avec son design dynamique, il plaira aux enfants fans du c\u00e9l\u00e8bre personnage et sera la pi\u00e8ce ma\u00eetresse de leur f\u00eate d'anniversaire.\n\n\ud83c\udfd9\ufe0f Le d\u00e9cor urbain en bas du g\u00e2teau \u00e9voque la ville de New York tandis que le bleu vibrant et les d\u00e9tails soigneusement dessin\u00e9s du masque de Spiderman capturent l'essence du personnage qui virvolte dans les airs avec ses toiles d'araign\u00e9es. Ce g\u00e2teau fera sensation aupr\u00e8s des enfants qui r\u00eavent d'aventures et de combats contre les m\u00e9chants aux c\u00f4t\u00e9s de leur h\u00e9ros pr\u00e9f\u00e9r\u00e9. \ud83d\udcaa\n" },
            { src: "57cec064-6849-4d30-9f16-6b92169316fb_w.jpg", title: "Sunshine", description: "\ud83c\udf89 Besoin d'un g\u00e2teau d'anniversaire qui fera briller les yeux de votre petite princesse? D\u00e9couvrez notre g\u00e2teau de 6 pouces, tout droit sorti d'un r\u00eave ensoleill\u00e9! \ud83c\udf3c\n\n\u2600\ufe0f Imaginez un soleil souriant coiff\u00e9 d'un n\u0153ud papillon rose, perch\u00e9 au sommet du g\u00e2teau. C'est notre secret pour ajouter une pinc\u00e9e de bonheur \u00e0 votre f\u00eate! \ud83c\udf1e\n\n\ud83c\udf38 Personnalis\u00e9 avec le doux message de votre choix et entour\u00e9 de fleurs d\u00e9licates, ce g\u00e2teau est aussi unique que votre petite. \ud83c\udf38\n\n\ud83c\udf6c Et pour le petit plus qui fait toute la diff\u00e9rence, on a ajout\u00e9 des boules en fondant et des perles de sucre color\u00e9es. Un vrai r\u00e9gal pour les yeux et les papilles! \ud83c\udf6c\n" },
            { src: "c1f5d7c0-ca9e-4e92-ade2-6f611a2703e0_w.jpg", title: "Tableau Cadre Or", description: "\ud83c\udf82 D\u00e9couvrez notre g\u00e2teau inspir\u00e9 d'une v\u00e9ritable toile d'artiste : une impression d'aquarelle sur papier comestible, magnifiquement encadr\u00e9e de feuilles d'or comestibles.\n\n\ud83d\udd8c\ufe0f Sur le dessus, deux pinceaux en fondant ajoutent la touche finale \u00e0 cette cr\u00e9ation.\n\n\ud83d\uddbc\ufe0f Choisissez l'oeuvre \u00e0 imprimer et le type de cadre que vous souhaitez.\n\n\ud83c\udfa8 En illustration sur notre photo, le splendide tableau de C\u00e9cile Grenier, aquarelliste reconnue de Qu\u00e9bec. Vous pouvez retrouver sa page Gallea \u00e0 cette adresse: https://www.gallea.ca/en/artists/cecile-grenier\n\n\ud83c\udf81 Id\u00e9al pour les passionn\u00e9s d'art ou pour marquer une occasion sp\u00e9ciale avec un g\u00e2teau unique." },
            { src: "a8d14b83-4d2b-4778-bc93-502e6cefe677_w.jpg", title: "Talon Aiguille 1", description: "\ud83d\udc60\u2728 Avec ses deux \u00e9tages \u00e9l\u00e9gamment habill\u00e9s d\u2019un fondant brillant aux motifs chics noir et blanc, ce g\u00e2teau respire l\u2019\u00e9l\u00e9gance et la f\u00e9minit\u00e9. Le superbe talon aiguille argent\u00e9 au sommet, entour\u00e9 de fleurs d\u00e9licates, fera chavirer le c\u0153ur des passionn\u00e9es de mode.\n\n\ud83d\udc84\ud83d\udc5c Un rouge \u00e0 l\u00e8vres gourmand, un petit sac \u00e0 main tendance et un collier de perles nacr\u00e9es, tous r\u00e9alis\u00e9s avec soin en p\u00e2te \u00e0 sucre, rappellent les pr\u00e9cieux instants pass\u00e9s entre amies ou en famille. Une cr\u00e9ation chaleureuse qui c\u00e9l\u00e8bre avec originalit\u00e9 la complicit\u00e9 et la douceur des liens qui nous unissent.\n\n\ud83c\udf38\ud83c\udf81 Faites plaisir \u00e0 une femme qui vous est ch\u00e8re, qu'elle soit votre maman, votre s\u0153ur ou votre meilleure amie, en lui offrant cette d\u00e9licieuse cr\u00e9ation qui illuminera son regard et rendra le moment inoubliable." },
            { src: "66783e25-1e75-4f27-a2e5-75012f1cab96_w.jpg", title: "Toutou-Doudou Lapin", description: "\ud83c\udf82 Regardez ce joli g\u00e2teau avec son lapinou rose tout doux en fondant! Il a m\u00eame une petite fleur sur sa t\u00eate! \ud83c\udf3c\n\n\ud83d\udc30 Pour tous les petits c\u0153urs qui adorent les lapinous, ce g\u00e2teau est fait pour vous! Et le lapinou... il a m\u00eame une doudou entre ses deux petites pattes! C'est tellement mignon! \ud83d\udc07\ud83d\udc95\n\n\ud83c\udf89 Et ce n'est pas tout! On peut y \u00e9crire le pr\u00e9nom et l'\u00e2ge de la star de la f\u00eate sur des petits drapeaux roses tout jolis. Et autour? Des rubans roses avec un n\u0153ud! \ud83c\udf80\n\n\ud83d\udc9d Si vous voulez offrir un gros c\u00e2lin en g\u00e2teau, c'est celui-ci qu'il vous faut! Parfait pour faire sourire et c\u00e2liner avec les yeux! \ud83e\udd70" },
            { src: "d352f1ab-b746-40c4-ba03-b1f39d8fe3d2_w.jpg", title: "Valise Vol 70", description: "\n\ud83d\uddfa\ufe0f Savourez le go\u00fbt des voyages d'hier et des aventures de toute une vie avec notre g\u00e2teau Valise Vol 70. Son allure vintage est un hommage aux r\u00e9cits et aux exp\u00e9ditions qui ont marqu\u00e9 l'existence. C'est la touche sucr\u00e9e id\u00e9ale pour les festivit\u00e9s de chaque \u00e2me exploratrice.\n\n\ud83d\udeeb Inspir\u00e9 des \u00e9pop\u00e9es d'antan, notre g\u00e2teau est une d\u00e9dicace aux retrait\u00e9s en qu\u00eate de nouvelles aventures ou qui ch\u00e9rissent les souvenirs de leurs voyages pass\u00e9s. Personnalisez-le avec une \u00e9tiquette nominative pour un hommage aussi unique que leurs histoires.\n\n\ud83c\udf10 Ce n'est pas qu'un g\u00e2teau, c'est un passeport pour un voyage dans le temps, une fen\u00eatre ouverte sur les r\u00e9cits du pass\u00e9 avec un clin d'\u0153il gourmand au pr\u00e9sent. Parfait pour les r\u00e9unions familiales ou les c\u00e9l\u00e9brations entre amis, il incite au partage des r\u00e9cits de vie et des voyages \u00e9piques.\n\n\ud83c\udf82 Tout est mangeable sur ce g\u00e2teau! Invitez vos proches \u00e0 une aventure culinaire avec notre g\u00e2teau Valise Vol 70, et c\u00e9l\u00e9brez avec eux la beaut\u00e9 de l'\u00e2ge et la richesse des exp\u00e9riences v\u00e9cues. C'est un hommage \u00e0 leur esprit inlassable et \u00e0 leur amour des d\u00e9couvertes." },

        ],

        galleryTheme: {
            thumbnail: {
                background: 'transparent',
                backgroundImage: 'linear-gradient(315deg, rgba(254,251,247,1) 0%, rgba(249,244,237) 90%)',
                titleBgColor: 'transparent',
                titleShadow: '#FFF',
                descriptionShadow: '#FFF',
                titleColor: 'rgba(256,256,256,1)',
                borderRadius: 'transparent',
                borderColor: 'transparent'
            }
        },
        thumbnailWaitImageLoaded: true,
        thumbnailDisplayTransition: 'scaleUp',
        thumbnailHoverEffect2: 'toolsSlideDown|label_backgroundColor_rgba(249,243,236,0.8)_rgba(98,62,23,0.7)',
        thumbnailToolbarImage: { topRight: 'share'},
        thumbnailBorderVertical: 0,
        thumbnailBorderHorizontal: 0,
        thumbnailGutterWidth: 20,
        thumbnailGutterHeight: 20,
        thumbnailWidth: 200,
        thumbnailHeight: 300,
        thumbnailLabel: {
            valign: "center",
            position: 'overImage',
            titleFontSize: '1.1em',
            displayDescription: false,
        },
        viewerTheme: 'dark',
        viewerHideToolsDelay: 60000000,
        viewerGalleryTWidth: 60,
        viewerGalleryTHeight: 80,
        viewerToolbar: {
            display: true,
            fullWidth: true,
            standard: 'label',
            minimized: 'label',
        },
        viewerTools: {
            topLeft: 'pageCounter',
            topRight: 'shareButton, infoButton, closeButton'
        },
    });
});

